[class^="icon16-"],
[class*=" icon16-"] {
  width: 16px;
  height: 16px;
  line-height: 1;
  background-image: url("images/icons.16.png");
  background-position: 0px 0px;
  background-repeat: no-repeat;
  margin-top: 0px;
  margin-bottom: 0px;
  display: inline-block;
  vertical-align: text-top;
}

.icon16-cart {
  background-position: 0px 0;
}
.active .icon16-cart {
  background-position: 0px -16px;
}
.icon16-lang {
  background-position: -16px 0;
  margin-top: 3px;
}
.active .icon16-lang {
  background-position: -16px -16px;
}
.icon16-carret {
  background-position: -32px 0;
}
.active .icon16-carret {
  background-position: -32px -16px;
}
.icon16-phone {
  background-position: -48px 0;
}
.icon16-processing {
  background-image: url("images/loader-square16.gif");
  background-position: 0px 0;
}
