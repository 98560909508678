.pagination,
.pagination-filter {
  border-radius: 2px;
  cursor: default;
  color: #555;
}

.pagination a,
.pagination span,
.pagination-filter {
  border: 1px solid #eee;
  border-radius: 2px;
  margin: 0 1px;
  padding: 2px 8px;
}

.pagination a,
.pagination-filter {
  margin-left: 1px;
  color: #49e;
}
.pagination a:hover,
.pagination-filter:hover {
  background: #eee;
  color: #555;
  text-decoration: none;
}

.pagination span {
  background-color: #eee;
  cursor: pointer;
}
.pagination span.page,
.pagination-filter.active {
  background-color: #49e;
  border: 1px solid #49e;
  color: white;
}

.pagination .disabled {
  background: none;
  border: none;
  color: #ccc;
  cursor: default;
}

.pagination .prev {
  padding-left: 4px;
}
.pagination .next {
  padding-right: 4px;
}

.paginate-bottom {
  clear: both;
  padding-top: 10px;
}

.paginate-top {
  clear: both;
  padding-bottom: 10px;
}

.pagination .ellipsis {
  background: none;
  border: none;
  cursor: default;
  margin: 0 2px 0 0;
}

#gallery_search_go,
span.remove-search-terms {
  color: #999;
  cursor: pointer;
  font-size: 18px;
  line-height: 15px;
  margin-left: -27px;
  padding: 5px;
  position: absolute;
}

#gallery_search_go:hover,
span.remove-search-terms:hover {
  color: #555;
}
