.rating-widget-16 {
  max-width: 100px;
  color: inherit;
}

.rating-widget-16 > span {
  max-width: 100px;
  color: inherit;
  font-size: smaller;
  line-height: 16px;
}

/* http://www.komodomedia.com/blog/2006/01/css-star-rating-part-deux/ */
.rating-16 {
  list-style: none;
  margin: 0px;
  padding: 0px;
  width: 80px;
  height: 16px;
  position: relative;
  overflow: hidden;
  background: url(images/rating-16_2.png) top left repeat-x;
}
.rating-16 li {
  padding: 0px;
  margin: 0px;
  width: 16px;
  height: 16px;
  /*\*/
  float: left;
  /* */
}
.rating-16 li a {
  display: block;
  width: 16px;
  height: 16px;
  line-height: 16px;
  text-decoration: none;
  text-indent: -9000px;
  z-index: 20;
  position: absolute;
  padding: 0px;
  overflow: hidden;
}
.rating-16 li a:hover {
  background: url(images/rating-16_2.png) left bottom;
  z-index: 2;
  left: 0px;
  border: none;
}
.rating-16 a.rate-1 {
  left: 0px;
}
.rating-16 a.rate-1:hover {
  width: 16px;
}
.rating-16 a.rate-2 {
  left: 16px;
}
.rating-16 a.rate-2:hover {
  width: 32px;
}
.rating-16 a.rate-3 {
  left: 32px;
}
.rating-16 a.rate-3:hover {
  width: 48px;
}
.rating-16 a.rate-4 {
  left: 48px;
}
.rating-16 a.rate-4:hover {
  width: 64px;
}
.rating-16 a.rate-5 {
  left: 64px;
}
.rating-16 a.rate-5:hover {
  width: 80px;
}
.rating-16 li.current-rating {
  background: url(images/rating-16_2.png) left center;
  position: absolute;
  height: 16px;
  display: block;
  text-indent: -9000px;
  z-index: 1;
  left: 0;
  padding: 0px;
  margin: 0px;
}

.rating-widget-32 {
  width: 160px;
  color: inherit;
}

.rating-widget-32 > span {
  width: 160px;
  color: inherit;
  line-height: 32px;
}

.rating-32 {
  list-style: none;
  margin: 0px;
  padding: 0px;
  width: 160px;
  height: 32px;
  position: relative;
  overflow: hidden;
  background: url(images/rating-32.png) top left repeat-x;
}
.rating-32 li {
  padding: 0px;
  margin: 0px;
  width: 32px;
  height: 32px;
  /*\*/
  float: left;
  /* */
}
.rating-32 li a {
  display: block;
  width: 32px;
  height: 32px;
  line-height: 32px;
  text-decoration: none;
  text-indent: -9000px;
  z-index: 20;
  position: absolute;
  padding: 0px;
  overflow: hidden;
}
.rating-32 li a:hover {
  background: url(images/rating-32.png) left bottom;
  z-index: 2;
  left: 0px;
  border: none;
}
.rating-32 a.rate-1 {
  left: 0px;
}
.rating-32 a.rate-1:hover {
  width: 32px;
}
.rating-32 a.rate-2 {
  left: 32px;
}
.rating-32 a.rate-2:hover {
  width: 64px;
}
.rating-32 a.rate-3 {
  left: 64px;
}
.rating-32 a.rate-3:hover {
  width: 96px;
}
.rating-32 a.rate-4 {
  left: 96px;
}
.rating-32 a.rate-4:hover {
  width: 128px;
}
.rating-32 a.rate-5 {
  left: 128px;
}
.rating-32 a.rate-5:hover {
  width: 160px;
}
.rating-32 li.current-rating {
  background: url(images/rating-32.png) left center;
  position: absolute;
  height: 32px;
  display: block;
  text-indent: -9000px;
  z-index: 1;
  padding: 0px;
  margin: 0px;
  left: 0;
}

.favorite {
  background: transparent url(images/rate.png) 0px 0 no-repeat;
  width: 32px;
  height: 32px;
}
.favorite:hover {
  background-position: 0 -32px;
}
.favorite:active {
  background-position: 0 -64px;
}
.favorite.on {
  background-position: -32px 0px;
}
.favorite.on:hover {
  background-position: -32px -32px;
}
.favorite.on:active {
  background-position: -32px -64px;
}
.favorite.loading {
  background: url(images/loader.ddd.16.gif) no-repeat center center;
}
