#popup_container {
/*  font-family: Arial, sans-serif;
    font-size: 12px; */
    min-width: 300px; /* Dialog will be no smaller than this */
    max-width: 600px; /* Dialog will wrap after this width */
    background: white;
    border: solid 1px #ccc;
    color: #333;
    border-radius: 3px;
    box-shadow: 0 0 10px rgba(0,0,0,0.5);
}

#popup_title {
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    line-height: 1.75em;
    color: #333333;
    border: 0;
    border-bottom: solid 1px #CCCCCC;
    cursor: default;
    padding: 0em;
    margin: 0 10px 0 10px;
}

#popup_content {
    background: 16px 16px no-repeat url(images/info.svg);
    padding: 1em 1.75em;
    margin: 0em;
}

#popup_content.alert {
    background-image: url(images/info.svg);
}

#popup_content.confirm {
    background-image: url(images/warning.png);
}

#popup_content.prompt {
    background-image: url(images/help_gray.png);
}

#popup_message {
    padding-left: 48px;
    color: #333333;
}

#popup_panel {
    text-align: center;
    margin: 1em 0em 0em 1em;
}

#popup_prompt {
    margin: .5em 0em;
}
