.social-foldable-container {
  position: relative;
  min-height: 27px;
}

.social-foldable-inner-container {
  position: absolute;
  left: 0;
  top: 0;
  padding-top: 2px;
  border-bottom: solid 1px black;
}

.social-foldable-inner-container.social-folded {
  overflow: hidden;
  height: 27px;
  /*border: 1px solid transparent;*/
}

.social-foldable-inner-container.social-unfolded {
  height: auto;
  z-index: 4;
  /* 	background-color:rgb(255,255,255); fallback
	background-color:rgba(255,255,255,0.90);
	filter: alpha(opacity = 90);
	border: 1px solid black;
	border-radius: 7px;
	-webkit-border-radius: 7px;
	-moz-border-radius: 7px;
 */
}

/*.social-foldable-items {
	height: auto;
	border: none;
	overflow: auto;
}

.social-foldable-items.folded {
	overflow: hidden;
	height: 20px;
	border-bottom: 1px solid black;
}*/

.social-foldable-items {
  position: absolute;
  top: 0px;
  left: 0px;
}

.social-foldable-items.folded {
  top: 10px;
}

.social-item-32 {
  background-image: url("images/social_32.png");
  width: 32px;
  height: 32px;
  cursor: pointer;
}

.social-item-32.social-facebook {
  background-position: 0px 0px;
}

.social-item-32.social-twitter {
  background-position: -32px 0px;
}

.social-item-32.social-google {
  background-position: -64px 0px;
}

.social-item-32.social-empty {
  background: none;
  cursor: pointer;
}
