table.cart {
  width: 100%;
  border-spacing: 1px;
  border-collapse: collapse;
  border: solid 1px transparent;
  background-color: transparent;
}

table.cart th {
  margin-bottom: 2px;
  padding: 8px 8px;
  background-color: transparent;
}

table.cart .header th {
  font-size: 12pt;
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 4px;
  color: #333;
  line-height: 24px;
  text-shadow: none;
}

table.cart td {
  padding: 4px;
  background-color: white;
  vertical-align: top;
}

table.cart tr.cartitem:hover {
  background-color: yellow;
}
table.cart tr.cartitem.odd0 td {
  background-color: #f4f7fb;
}
table.cart tr.cartitem.odd1 td {
  background-color: #ffffff;
}
table.cart tr.cartitem.odd0.selected td {
  background-color: #ffffe0;
}
table.cart tr.cartitem.odd1.selected td {
  background-color: #ffffe6;
}

table.cart tr.shipping-us td,
table.cart tr.country-us td {
  background-color: #aaffff;
}

table.cart tr.shipping-fr td {
  background-color: #ff7308;
}

#payment label {
  height: 50px;
  margin: 0;
  padding: 0;
  border: 1px solid #ccc;
  border-bottom: none;
  line-height: 50px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

#payment label:first-child {
  border-radius: 3px 3px 0 0;
}

#payment label:last-child {
  border-radius: 0 0 3px 3px;
  border-bottom: 1px solid #ccc;
}

#payment label input[type="radio"] {
  float: none;
  margin: 0 0 0 10px;
}

#payment label span:before {
  content: "";
  display: inline-block;
  width: 60px;
  height: 40px;
  background: transparent url(images/payment_methods.png) 0 0 no-repeat;
  vertical-align: middle;
}

#payment label[data-value="PAYMENT_PAYPAL"] span,
#payment label[data-value="PAYMENT_PAYPAL_STANDARD"] span {
  margin-left: 5px;
  color: transparent;
}
#payment label[data-value="PAYMENT_PAYPAL"] span:before,
#payment label[data-value="PAYMENT_PAYPAL_STANDARD"] span:before {
  background-position: 0 0;
  width: 80px;
}
#payment label[data-value="PAYMENT_CHEQUE"] span:before {
  background-position: 0 -40px;
}
#payment label[data-value="PAYMENT_STRIPE"] span:before,
#payment label[data-value="PAYMENT_PAYBOX"] span:before {
  background-position: 0 -80px;
}
#payment label[data-value="PAYMENT_BANK_TRANSFER"] span:before {
  background-position: 0 -120px;
}
#payment label[data-value="PAYMENT_INVOICE"] span:before {
  background-position: 0 -160px;
}
#payment label[data-value="PAYMENT_DUMMY"] span:before {
  background-position: 0 -200px;
}
#payment label[data-value="PAYMENT_CREDITNOTE"] span:before {
  background-position: 0 -240px;
}

#payment label[data-value="PAYMENT_STRIPE"] span:after {
  content: "";
  display: block;
  position: absolute;
  right: 5px;
  top: 5px;
  height: 40px;
  width: 90px;
  background: transparent url(images/payment_methods.png) 0 -280px no-repeat;
}

#payment label[data-value="PAYMENT_PAYBOX"] span:after {
  content: "";
  display: block;
  position: absolute;
  right: 5px;
  top: 5px;
  height: 40px;
  width: 90px;
  background: transparent url(images/payment_methods.png) 25px -280px no-repeat;
}

#payment label.selected {
  background: #ffffff;
  border: 1px solid #49e;
}
#payment label.selected + label {
  border-top: none;
}

@media (max-width: 767px) {
  #payment {
    text-align: center;
  }
}

/* iPhone 6 is 375px */
@media (max-width: 374px) {
  #payment label[data-value="PAYMENT_STRIPE"] span:after,
  #payment label[data-value="PAYMENT_PAYBOX"] span:after {
    display: none;
  }
}
