html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-weight: inherit;
  font-style: inherit;
  font-size: 100%;
  font-family: inherit;
  color: inherit;
  vertical-align: baseline;
  text-indent: 0;
}

/* remember to define focus styles! */
:focus {
  outline: 0;
}

sup {
  vertical-align: super;
}
ul {
  list-style: none;
}
ol {
  list-style: numeric;
  list-style-position: inside;
}
/* tables still need 'cellspacing="0"' in the markup */
table {
  border-collapse: separate;
  border-spacing: 0;
}
caption,
th,
td {
  text-align: left;
  font-weight: normal;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
}
blockquote,
q {
  quotes: "" "";
}

dt {
  font-weight: bold;
}

.Sculpteo3DViewer {
  /* Direct all pointer events to JavaScript code. */
  -ms-touch-action: double-tap-zoom;
}

.hidden {
  display: none;
}
.center,
.acenter {
  text-align: center !important;
}
.left,
.aleft {
  text-align: left !important;
}
.right,
.aright {
  text-align: right !important;
}
.atop {
  vertical-align: top;
}
.amiddle {
  vertical-align: middle;
}
.abottom {
  vertical-align: bottom;
}
.fleft {
  float: left !important;
}
.fright {
  float: right !important;
}
.pull-right {
  float: right;
}
.pull-left {
  float: left;
}
.clearfix {
  zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
  line-height: 0;
}
.clearfix:after {
  clear: both;
}

.clr,
.clear,
.clears {
  clear: both;
}
.sm-clr {
  display: none;
}
.inline {
  display: inline;
}
.inline-block {
  display: inline-block;
}
.relative {
  position: relative;
}
.absolute {
  position: absolute;
}
.fixed {
  position: fixed;
}
.block {
  display: block;
}
.inline-table {
  display: inline-table;
}
.bound {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.bound-x {
  left: 0;
  right: 0;
}
.bound-8 {
  top: 8px;
  bottom: 8px;
  left: 8px;
  right: 8px;
}
.abs-bottom {
  bottom: 0;
}
.abs-right {
  right: 0;
}
.abs-top {
  top: 0;
}
.mauto {
  margin: 0 auto;
}
.wide {
  width: 100% !important;
}
.embedded-wide {
  width: 980px !important;
  overflow-x: hidden;
  margin: auto;
}
.embedded-content {
  width: 980px !important;
  padding: 0 !important;
}
.high {
  height: 100% !important;
}
.no-margin {
  margin: 0 !important;
}
.no-padding {
  padding: 0 !important;
}

.w-full {
  width: 100%;
}
.w180 {
  width: 180px;
}
.w240 {
  width: 240px;
}
.w320 {
  width: 320px;
}
.w480 {
  width: 480px;
}
.w640 {
  width: 640px;
}
.w720 {
  width: 720px;
}
.m2t {
  margin-top: 2px !important;
}
.m4 {
  margin: 4px !important;
}
.m4t {
  margin-top: 4px !important;
}
.m4b {
  margin-bottom: 4px !important;
}
.m4r {
  margin-right: 4px !important;
}
.m4l {
  margin-left: 4px !important;
}
.m8t {
  margin-top: 8px !important;
}
.m8b {
  margin-bottom: 8px !important;
}
.m8l {
  margin-left: 8px !important;
}
.m8r {
  margin-right: 8px !important;
}
.m16 {
  margin: 16px !important;
}
.m16t {
  margin-top: 16px !important;
}
.m16b {
  margin-bottom: 16px !important;
}
.m16l {
  margin-left: 16px !important;
}
.m16r {
  margin-right: 16px !important;
}
.m24 {
  margin: 24px !important;
}
.m24t {
  margin-top: 24px !important;
}
.m24b {
  margin-bottom: 24px !important;
}
.m24l {
  margin-left: 24px !important;
}
.m24r {
  margin-right: 24px !important;
}
.m28r {
  margin-right: 28px !important;
}
.m48l {
  margin-left: 48px !important;
}
.m48r {
  margin-right: 48px !important;
}
.p4 {
  padding: 4px;
}
.p4b {
  padding-bottom: 4px;
}
.p4t {
  padding-top: 4px;
}
.p4l {
  padding-left: 4px;
}
.p4r {
  padding-right: 4px;
}
.p6 {
  padding: 6px;
}
.p6l {
  padding-left: 6px;
}
.p6r {
  padding-right: 6px;
}
.p8 {
  padding: 8px;
}
.p8l {
  padding-left: 8px;
}
.p8r {
  padding-right: 8px;
}
.p8t {
  padding-top: 8px;
}
.p8b {
  padding-bottom: 8px;
}
.p10 {
  padding: 10px;
}
.p10l {
  padding-left: 10px;
}
.p10r {
  padding-right: 10px;
}
.p16 {
  padding: 16px;
}
.p16t {
  padding-top: 16px;
}
.p16b {
  padding-bottom: 16px;
}
.p16l {
  padding-left: 16px;
}
.p16r {
  padding-right: 16px;
}
.p24 {
  padding: 24px;
}
.p24t {
  padding-top: 24px;
}
.p24b {
  padding-bottom: 24px;
}
.p24l {
  padding-left: 24px;
}
.p24r {
  padding-right: 24px;
}
.p32l {
  padding-left: 32px;
}
.p48l {
  padding-left: 48px;
}
.h50 {
  height: 50px;
}
.no-border {
  border: 0;
}
.border-dotted-left {
  border-left: 1px dotted #aaa;
}
.border-dotted-right {
  border-right: 1px dotted #aaa;
}
.border-dotted-top {
  border-top: 1px dotted #aaa;
}
.border-dotted-bottom {
  border-bottom: 1px dotted #aaa;
}
.border-dotted {
  border: 1px dotted #aaa !important;
}
.border-solid-left {
  border-left: 1px solid #888 !important;
}
.border-solid-right {
  border-right: 1px solid #888 !important;
}
.border-solid-top {
  border-top: 1px solid #888 !important;
}
.border-solid-bottom {
  border-bottom: 1px solid #888 !important;
}
.border-solid {
  border: 1px solid #888 !important;
}
.border-white {
  border-color: white !important;
}

.border-red {
  border-color: red !important;
}

.border-full {
  border: 1px solid #888;
  border-top-style: inset;
  border-right-style: inset;
  border-bottom-style: inset;
  border-left-style: inset;
}
.border-full td,
.border-full tr,
.border-full th {
  border: 1px solid #888;
  border-top-style: inset;
  border-right-style: inset;
  border-bottom-style: inset;
  border-left-style: inset;
}
.max-round {
  border-radius: 7px;
}
.round {
  border-radius: 3px;
}
.round-tl {
  border-top-left-radius: 3px;
}
.round-tr {
  border-top-right-radius: 3px;
}
.round-bl {
  border-bottom-left-radius: 3px;
}
.round-br {
  border-bottom-right-radius: 3px;
}

.bgtransparent {
  background-image: none;
  background-color: transparent !important;
}
.white {
  color: white !important;
}
.black {
  color: black !important;
}
.gray {
  color: #aaa !important;
}
.lightgray {
  color: #ccc !important;
}
.bgwhite {
  background-image: none;
  background-color: white !important;
}

.bggrey {
  background-image: none;
  background-color: #ccc !important;
}
.bglightgrey {
  background-image: none;
  background-color: #eee !important;
}
.bgblack {
  background-image: none;
  background-color: black !important;
}

.bggreen {
  background-image: none;
  background-color: #d7f9be !important;
}

.bgblue {
  background-image: none;
  background-color: #1c85e0 !important;
}
.bgred {
  background-image: none;
  background-color: rgb(254, 144, 144) !important;
}
.bgorange {
  background-image: none;
  background-color: #ffcc6d !important;
}

.nowrap {
  white-space: nowrap;
}
.gray {
  color: gray;
}
.cleargray {
  color: #ccc;
}
.red {
  color: red;
}
.orange {
  color: orange;
}
.green {
  color: green;
}
.yellow {
  color: yellow;
}
.blue {
  color: blue;
}
.bg-soft-gray {
  background-color: #ddd;
}
.outline {
  outline: 1px red solid;
}
.size10 {
  font-size: 10px !important;
}
.size14 {
  font-size: 14px !important;
}
.size16 {
  font-size: 16px !important;
}
.size18 {
  font-size: 18px !important;
}
.size20 {
  font-size: 20px !important;
}

.overauto {
  overflow: auto;
}

/*
	Sculpteo general style
*/

body {
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: subpixel-antialiased;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  color: black;
  font-size: 13px;
  text-align: center;
  position: relative;
}

@font-face {
  font-family: "sculpteo-icons";
  src: url("fonts/sculpteo-icons.eot");
  src: url("fonts/sculpteo-icons.eot?#iefix") format("embedded-opentype"),
    url("fonts/sculpteo-icons.woff") format("woff"),
    url("fonts/sculpteo-icons.ttf") format("truetype"),
    url("fonts/sculpteo-icons.svg#sculpteo-icons") format("svg");
  /*font-weight: normal;
	font-style: normal;*/
}

#site_content {
  margin: 0 auto;
  width: 960px;
  padding: 0 10px;
}

.wrapper,
.wrapper980 {
  text-align: left !important;
  margin: 0 auto;
  width: 982px;
  position: relative;
}

.left_col {
  width: 688px;
  float: left;
}
.right_col {
  width: 268px;
  float: right;
}

.wrapper480 {
  text-align: left !important;
  position: relative;
  margin: 0 auto;
  width: 480px;
}

.trebuchet {
  font-family: "Trebuchet MS", "Arial", sans-serif !important;
}

h1,
h1.h1 {
  text-shadow: none;
  font-size: 28pt;
  color: #111;
}
h2,
h2.h2 {
  font-size: 20pt;
  font-weight: bold;
  margin-bottom: 4px;
  color: #111;
  text-shadow: none;
}
h3,
h3.h3 {
  font-size: 14pt;
  color: #111;
  font-weight: bold;
}
h4,
h4.h4 {
  font-size: 18px;
  color: #111;
  font-weight: normal;
}
h5,
h5.h5 {
  font-size: 13pt;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0;
  margin-bottom: 4px;
  color: #111;
  text-shadow: none;
  margin: auto;
}
h6,
h6.h6 {
  font-size: 11px;
  font-style: normal;
}
.fontface body,
.fontface h1,
.fontface h2,
.fontface h3,
.fontface h4,
.fontface p,
.fontface td,
.fontface th,
.fontface li {
  text-rendering: optimizelegibility;
  font-smooth: always;
  -webkit-font-smoothing: antialiased;
}
.fontface h1,
.fontface strong,
.fontface .strong,
.fontface h3,
.fontface b,
.fontface .b {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
}

.fontface h1.normal,
.fontface h3.normal {
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
}

.h1 {
  font-weight: bold;
  text-shadow: none;
  font-size: 22pt;
  line-height: 22pt;
  color: #111;
  min-height: 30px;
}
.h2 {
  font-size: 12pt;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 4px;
  color: #111;
  line-height: 24px;
  text-shadow: none;
}
.h3 {
  font-size: 13pt;
  color: #111;
  font-weight: bold;
  line-height: normal;
  margin-bottom: 0;
  text-align: left;
  margin-top: 4px;
}
.h4 {
  font-size: 13px;
  color: #898989;
  font-weight: normal;
  line-height: normal;
}
.h5 {
  font-size: 12pt;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0;
  margin-bottom: 4px;
  color: #111;
  line-height: 24px;
  text-shadow: none;
  margin: auto;
}
.h6 {
  font-size: 11px;
  font-style: normal;
}

.nobold {
  font-weight: normal;
}
strong,
.strong,
.bold {
  font-weight: bold;
}

em,
.em,
.italic {
  font-style: italic;
}

small,
.small {
  font-size: 11px;
}
.medium {
  font-size: 13px;
}
.big {
  font-size: 16px;
}
.bigger {
  font-size: 24px;
}

p,
.p {
  -webkit-font-smoothing: subpixel-antialiased;
  color: inherit;
  font-weight: normal;
  letter-spacing: 0;
}
.p {
  font-size: 13px !important;
}

a,
.a {
  color: #1c85e0;
  text-decoration: none;
}
a:hover,
.a:hover {
  text-decoration: underline;
  cursor: pointer;
}

ul {
  list-style-type: none;
}
hr {
  height: 0;
  width: 100%;
  border: 0;
  border-bottom: #ddd 1px solid;
}
hr.dotted {
  border: 0;
  border-top: #aaa 1px dotted;
}

label {
  -webkit-font-smoothing: subpixel-antialiased;
  font-size: 15px;
  color: #000;
  font-weight: normal;
  letter-spacing: 0;
  display: block;
  margin-bottom: 0;
  margin-top: 2px;
}
label.first {
  margin-top: 0;
}
label.for_checkbox,
label.for_radio {
  float: left !important;
  font-size: inherit;
  color: inherit;
  margin-top: 0;
  margin-bottom: 0;
  display: block;
}

label.for_checkbox:hover,
label.for_radio:hover {
  outline: 1px dotted gray;
  cursor: pointer;
}

.checkbox_right_of_label {
  width: 14px;
  height: 14px;
  padding: 0;
  margin: 0;
  vertical-align: text-bottom;
  position: relative;
  top: -1px;
  overflow: hidden;
}

input,
textarea {
  box-sizing: border-box;
}

input[type="text"],
input[type="email"],
input[type="password"],
input[type="file"],
textarea {
  font-size: 14px;
  padding: 4px;
  border-radius: 3px;
  border: solid 1px #ccc;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
  width: 100%;
}
input[type="checkbox"] {
  border: 0;
}
input[type="radio"] {
  border: 0;
}
textarea {
  -webkit-appearance: textarea;
  -moz-appearance: textarea;
  width: 100%;
  height: 180px;
  resize: vertical;
}
textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus {
  background-color: #f2faff;
  transition: background-color 0.3s;
}
.textarea-with-maxlength-hint {
  position: relative;
}
.textarea-with-maxlength-hint span {
  position: absolute;
  right: 0;
  bottom: 4px;
  width: 30px;
  padding: 5px 0;
  color: #5af;
  font-weight: bold;
  text-align: center;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 3px 0;
}
input[type="submit"],
button {
  -webkit-appearance: button;
  -moz-appearance: button;
}
select {
  font-size: 16px;
}
option {
  font-size: 14px;
}
input.percentInput {
  background: white url(images/percent.png) 2px 6px no-repeat;
  padding-left: 24px;
  width: 4.5em;
}
input.euro {
  background: white url(images/euro.png) 2px 6px no-repeat;
  padding-left: 24px;
  width: 4.5em;
}
.on_whitebg input,
input.on_whitebg,
.on_whitebg textarea,
textarea.on_whitebg {
  border: 1px #ccc solid !important;
}
.on_whitebg input[type="radio"],
.on_whitebg input[type="checkbox"] {
  border: 0 !important;
}
.w640 textarea {
  width: 598px;
}

.on_darkbg {
  border: 1px #ccc solid !important;
  background: #f5f5f5;
}

.on_darkbg:focus {
  background: #fbfcc6;
}

.on_graybg {
  border: 1px #ccc solid !important;
  background: white;
}

.on_graybg:focus {
  background: #fbfcc6;
}

/*
	Sculpteo Layout Parts
*/

.search_top_block span {
  color: white;
}

.global_search_go {
  float: right;
  display: block;
  height: 24px;
  width: 24px;
  background: url(images/search_button_small.png) no-repeat;
}

.global_search_go:hover {
  background-position: -24px 0;
}

.gallery_search_go {
  display: block;
  height: 32px;
  width: 32px;
  background: url(images/search_button.png) no-repeat;
}

.gallery_search_go:hover {
  background-position: -32px 0;
}

/*
	Menu
*/
#menu {
  /*height:63px;*/
  color: white;
  margin: 0 9px 0 9px;
}

.navigation {
  height: 34px;
  /*background: transparent url(images/fond_menu.png) left;*/
}

#menu_blog {
  height: 145px;
  color: white;
  position: relative;
  background: black;
}
#menu_blog .wrapper {
  height: 145px;
  background: black url(images/bg_blogmenu.png) center top no-repeat;
}

#menu ul.tabs {
  height: 34px;
  list-style-type: none;
}
#menu ul.tabs li {
  float: left;
  height: 34px;
  position: relative;
  text-align: center;
}
#menu ul.tabs li a,
#menu ul.tabs li span {
  margin-top: 5px;
  display: block;
  padding: 4px;
  color: white;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25), 0 1px 0 rgba(255, 255, 255, 0.25);
  -moz-text-shadow: 0 -1px 1px rgba(0, 0, 0, 0.25),
    0 1px 1px rgba(255, 255, 255, 0.25);
  font-family: "Open Sans", sans-serif;
  text-transform: uppercase;
  text-decoration: none;
  cursor: pointer;
}
#menu ul.tabs li .gray {
  color: gray;
}
#menu ul.tabs li.selected {
  background: url("images/round-tab-110.png") no-repeat scroll left top #9daff7;
  height: 34px;
}
#menu ul.tabs li.selected a,
#menu ul.tabs li.selected span {
  color: white !important;
  /*text-shadow: 0px -1px 0px rgba(0,0,0,.25), 0px 1px 0px rgba(255,255,255,.75);
	-moz-text-shadow: 0px -1px 1px rgba(0,0,0,.75), 0px 1px 1px rgba(255,255,255,.75);*/
}
#menu ul.tabs .tab {
  width: 110px;
  margin-right: 10px;
  background: #5d6fb7 url(images/round-tab-110.png) left top no-repeat;
}

#menu ul.tabs .tab-pro {
  width: 160px;
  margin-left: 150px;
  margin-right: 10px;
  background: #5d6fb7 url(images/round-tab-160.png) left top no-repeat;
}

#menu ul.tabs .tab:hover {
  background-color: #112468;
}

#menu ul.tabs .tab .selected {
  background-color: #112468;
}

.search_top_block {
  position: absolute;
  top: 0;
  right: 0;
  height: 32px;
  width: 255px;
  background: #112468 url(images/bottom-left-round.png) left bottom no-repeat;
  line-height: 32px;
  font-weight: bold;
  color: white;
  font-size: 8pt;
}

#menu ul.tabs .tab-pro:hover {
  background-color: #112468;
}

a.sub-tab {
  margin-left: 16px;
  margin-right: 16px;
  font-size: 11px;
  font-family: "Open Sans", sans-serif !important;
}

/*
	Sculpteo Elements
*/

.button {
  background-image: url("images/button-160-blue.png");
  background-repeat: no-repeat;
  background-color: transparent;
  background-position: 0 0;
  padding: 0;
  margin: 0;
  width: 160px;
  height: 26px;
  display: inline-block;
  text-align: center;
  line-height: 26px;
  text-decoration: none;
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  color: white !important;
  text-shadow: rgba(255, 255, 255, 0.5) 0 1px 0, rgba(0, 0, 0, 0.3) 0 -1px 0;
  outline: 0;
  border: 0;
}

.button:hover {
  background-position: 0 -26px;
  cursor: pointer;
  text-decoration: none;
}
.button:active {
  background-position: 0 -52px;
  color: #eee;
  cursor: pointer;
}
.button .disabled,
.button[disabled],
.button .disabled:active,
.button[disabled]:active,
.button .disabled:hover {
  background-position: 0 -78px;
  color: #fff;
  cursor: default;
  padding: 0;
}

.button .button-240 {
  background-image: url("images/button-240-blue.png");
  width: 240px;
}

.button .button-yellow {
  background-image: url("images/button-160-yellow.png");
}

.button .redbutton {
  background-image: url(images/button-160-red.png);
  /*background-color:#ff3255;*/
}

.button .orangebutton {
  background-image: url(images/selection-orange.png);
  background-color: #efe457;
  background-position: 0 -33px;
  background-repeat: repeat-x;
}

.button .facebookbutton {
  background-image: url(images/selection-facebook.png);
  background-color: #3b5998;
  background-position: 0 -33px;
  background-repeat: repeat-x;
}

.button .greenbutton {
  background-image: url(images/button-160-green.png);
}

.button .lightbluebutton {
  background-image: url(images/selection-lightblue.png);
  background-color: #05e5f3;
  background-position: 0 -33px;
  background-repeat: repeat-x;
}

.big_button {
  background-image: url("images/button-big-160.png");
  background-repeat: no-repeat;
  background-color: transparent;
  background-position: 0 0;
  margin: 0;
  width: 148px;
  height: 27px;
  display: inline-block;
  text-align: center;
  line-height: 13px;
  padding: 6px;
  text-decoration: none;
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  color: white !important;
  text-shadow: rgba(255, 255, 255, 0.5) 0 1px 0, rgba(0, 0, 0, 0.3) 0 -1px 0;
  outline: 0;
  border: 0;
}

.big_button:hover {
  background-position: 0 -39px;
  cursor: pointer;
  text-decoration: none;
}
.big_button:active {
  background-position: 0 -78px;
  color: #eee;
  cursor: pointer;
}
.big_button .disabled,
.big_button[disabled],
.big_button .disabled:active,
.big_button[disabled]:active {
  background-position: 0 -117px;
  color: #fff;
  cursor: default;
  padding: 0;
}

/* OLD BUTTON */

.button-48 {
  width: 100%;
  height: 48px;
  position: absolute;
  text-decoration: none !important;
}

.button-48-body {
  background: transparent url(images/button-48.png) repeat-x 0 0;
  position: absolute;
  height: 48px;
  left: 8px;
  right: 8px;
}
.button-48-left {
  background: transparent url(images/button-48.png) no-repeat 0 -192px;
  position: absolute;
  height: 48px;
  width: 8px;
  left: 0;
}
.button-48-right {
  background: transparent url(images/button-48.png) no-repeat -40px -192px;
  position: absolute;
  height: 48px;
  width: 8px;
  right: 0;
}

.button-48:hover,
.button-48:active {
  text-decoration: none !important;
}
.button-48:hover .button-48-body {
  background-position: 0 -48px;
}
.button-48:hover .button-48-left {
  background-position: 0 -240px;
}
.button-48:hover .button-48-right {
  background-position: -40px -240px;
}
.button-48:active .button-48-body {
  background-position: 0 -96px;
}
.button-48:active .button-48-left {
  background-position: 0 -288px;
}
.button-48:active .button-48-right {
  background-position: -40px -288px;
}

.facebook-font {
  font-family: "lucida grande", tahoma, verdana, arial, sans-serif;
  font-variant: normal;
  text-transform: none;
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
}

/*
	Designers Bull
*/
.designers-bull {
  top: 0;
  left: 290px;
}

.designer-bull-graphic {
  background: transparent url(images/designer-bull-main-part.png) no-repeat;
  padding-left: 40px;
  height: 83px;
  width: 323px;
}

/*
	Messages panel growl
*/
#messages {
  position: fixed;
  z-index: 10000;
  right: 20px;
  top: 30px;
}

.share-panel {
  /*float:right;*/
  margin: auto;
  margin-top: -25px;
  background: url(images/share-panel96.png) no-repeat 0 0 transparent;
  width: 273px;
  height: 96px;
  margin-bottom: 12px;
  color: black;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 10px;
  position: relative;
  z-index: 100;
}
.share-panel a {
  padding: 0 2px;
}

input.search-icon {
  padding-left: 24px;
  background: white url(images/searchbar-keywords.png) 2px 3px no-repeat;
  width: inherit !important;
}

.thumbnail-progress {
  top: 42%;
  left: 10%;
  height: 16%;
  font-size: 12px;
  font-weight: bold;
  text-decoration: none;
  width: 80%;
  position: absolute;
  margin: 0;
  display: block;
  text-align: center;
  z-index: 100;
}

.thumbnail-progress-bar {
  bottom: 0;
  left: 0;
  height: 100%;
  position: absolute;
  display: block;
  background-color: #5fb5ff;
  border-radius: 5px;
  opacity: 0.75;
  z-index: 101;
}

.thumbnail-progress-text {
  opacity: 1;
  z-index: 102;
  line-height: 25px;
  font-size: 16px;
}

.form448 {
  width: 448px;
  border-collapse: separate;
  border-spacing: 2px;
}

.form448 .width100,
.form448 .width100 * {
  width: 100px;
}

.success16 {
  display: block;
  background: url(images/success16.png) 0 0 no-repeat;
  width: 16px;
  height: 16px;
}

.lateral_alert {
  position: absolute;
  left: -62px;
  top: 80px;
  width: 248px;
  height: 181px;
  z-index: 1000;
}

.lateral_alert_right {
  left: 8px;
  overflow: hidden;
  position: relative;
  width: 265px;
}

.lateral_alert_right div.close_button {
  width: 22px;
  height: 22px;
  position: relative;
  right: 10px;
  top: 10px;
  background: url("images/help_close_button.png") no-repeat;
  float: right;
}

.lateral_alert_right div.close_button:hover {
  background: url("images/help_close_button_hover.png") no-repeat;
}

.lateral_alert_pad {
  padding: 8px 12px;
}

.opac {
  background: transparent url(images/black-alpha80.png) repeat;
}

.loader16 {
  background: url(images/loader16.gif) no-repeat center center;
  width: 16px;
  height: 16px;
}

.uploadifyQueueItem {
  width: 480px;
}

.uploadifyQueueItem .cancel {
  float: right;
}
.uploadifyProgress {
  background-color: #ffffff;
  width: 460px;
}
.uploadifyProgressBar {
  background: #3d81e0 url(images/selection.png) 0 -32px repeat-x;
  width: 1px;
  height: 4px;
}
.round-progress-48 {
  width: 48px;
  height: 48px;
  background-image: url("images/round-progress-48.png");
  background-repeat: no-repeat;
  line-height: 48px;
  font-weight: bold;
  font-family: monospace;
  font-size: 12px;
  color: white;
  text-align: center;
  z-index: 10000;
}

#fileQueue {
  width: 480px;
  height: 30px;
  overflow: hidden;
  border: 0;
  margin: 0;
}

.segmentedControl {
  background: url(images/selection.png) 0 -3px repeat-x #fff;
  padding: 3px 0;
  margin: 0;
  color: #555;
  float: left;
  border: 1px #ccc solid;
  border-right: 0;
  cursor: pointer;
  text-align: center;
}
.segmentedControl .small {
  background-position: 0 -5px;
}
.segmentedControl-first {
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
}
.segmentedControl-last {
  border: 1px #ccc solid !important;
  border-bottom-right-radius: 7px;
  border-top-right-radius: 7px;
}
.segmentedControl .selected {
  background: url(images/selection.png) 0 -32px repeat-x #1c85e0;
  border-color: #2b78e4 !important;
  color: white;
}
.segmentedControl:active {
  background: url(images/selection.png) 0 -32px repeat-x #1c85e0;
  border-color: #2b78e4 !important;
  color: white;
}

.ui-slider-handle .ui-state-default .ui-corner-all {
  background: url(images/selection.png) 0 -32px repeat-x #1c85e0;
  border: 1px #2b78e4 solid !important;
  cursor: pointer;
  width: 8px;
}
.ui-slider-handle .ui-state-default .ui-corner-all .ui-state-hover {
  border-color: #fff !important;
}
.ui-slider-vertical .ui-slider-handle .ui-state-default .ui-corner-all {
  width: 1.2em;
  height: 8px;
}

.design-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
}

.close {
  position: absolute;
  top: 4px;
  right: 4px;
  height: 32px;
  width: 32px;
  background: transparent url(images/close.png) 0 0 no-repeat;
  direction: ltr;
  text-indent: -1000em;
  overflow: hidden;
}
.close:hover {
  background-position: 0 -32px;
}
.close:active {
  background-position: 0 -64px;
}

.avatar {
  width: 80px;
  height: 80px;
  background: transparent url(images/avatar_default.png) 0 0 no-repeat;
}

.avatar_small {
  width: 60px;
  height: 60px;
  background: transparent url(images/avatar_default.png) 0 0 no-repeat;
}

.avatar .company {
  background-position: -80px 0;
}

.design-overlay .clickanywhere {
  color: #777;
}

._3dTool {
  color: #111;
  left: 0;
  right: 0;
  border-radius: 7px;
  background-color: #ddd;
  margin-top: 4px;
}
._3dTool:first-child {
  margin-top: 0;
}

.favorite {
  background: transparent url(images/rate.png) 0 0 no-repeat;
  width: 32px;
  height: 32px;
}
.favorite:hover {
  background-position: 0 -32px;
}
.favorite:active {
  background-position: 0 -64px;
}
.favorite .on {
  background-position: -32px 0;
}
.favorite .on:hover {
  background-position: -32px -32px;
}
.favorite .on:active {
  background-position: -32px -64px;
}
.favorite .loading {
  background: url(images/loader.ddd.16.gif) no-repeat center center;
}

.keyring-pin1,
.keyring-pin2,
.keyring-pin3,
.keyring-pin4,
.keyring-pin5,
.keyring-pin6,
.keyring-pin7,
.keyring-pin8,
.keyring-pin9,
.keyring-pin10,
.keyring-pin11 {
  background: white url(images/keyringpins.png) 0 0 no-repeat;
  background-position: 0 0;
  width: 32px;
  height: 32px;
  border-radius: 7px;
  border: 2px #ffffff solid;
}

.keyring-pin1:hover,
.keyring-pin2:hover,
.keyring-pin3:hover,
.keyring-pin4:hover,
.keyring-pin5:hover,
.keyring-pin6:hover .keyring-pin7:hover,
.keyring-pin8:hover,
.keyring-pin9:hover,
.keyring-pin10:hover,
.keyring-pin11:hover {
  border: 2px #6aa4f5 solid;
}

.keyring-pin1:active,
.keyring-pin2:active,
.keyring-pin3:active,
.keyring-pin4:active,
.keyring-pin5:active,
.keyring-pin6:active,
.keyring-pin6:active,
.keyring-pin7:active,
.keyring-pin8:active,
.keyring-pin9:active,
.keyring-pin10:active,
.keyring-pin11:active {
  border: 2px #426b9f solid;
}

.keyring-pin1 .on,
.keyring-pin2 .on,
.keyring-pin3 .on,
.keyring-pin4 .on,
.keyring-pin5 .on,
.keyring-pin6 .on,
.keyring-pin7 .on,
.keyring-pin8 .on,
.keyring-pin9 .on,
.keyring-pin10 .on,
.keyring-pin11 .on {
  border: 2px #2b78e4 solid;
}

.keyring-pin2 {
  background-position: 0 -32px;
}
.keyring-pin3 {
  background-position: 0 -64px;
}
.keyring-pin4 {
  background-position: 0 -96px;
}
.keyring-pin5 {
  background-position: 0 -128px;
}
.keyring-pin6 {
  background-position: 0 -160px;
}

.alignment0,
.alignment1,
.alignment2 {
  width: 32px;
  height: 32px;
  border-radius: 7px;
  background-color: white;
  border: 2px #ffffff solid;
}

.alignment0:hover,
.alignment1:hover,
.alignment2:hover {
  border: 2px #6aa4f5 solid;
}

.alignment0:active,
.alignment1:active,
.alignment2:active {
  border: 2px #426b9f solid;
}

.alignment0 .on,
.alignment1 .on,
.alignment2 .on {
  border: 2px #2b78e4 solid;
}

.alignment0 {
  background: transparent url(images/align-left.png) 0 0 no-repeat;
}
.alignment1 {
  background: transparent url(images/align-center.png) 0 0 no-repeat;
}
.alignment2 {
  background: transparent url(images/align-right.png) 0 0 no-repeat;
}

.geometry1,
.geometry2,
.geometry3,
.geometry4,
.geometry5,
.geometry6,
.geometry7,
.geometry8,
.geometry9,
.geometry10,
.geometry11,
.geometry12,
.geometry13,
.geometry14,
.geometry15 {
  background: transparent url(images/picto_geometry_all.png) 0 0 no-repeat;
  background-position: 0 0;
  width: 32px;
  height: 32px;
  border-radius: 7px;
  background-color: white;
  border: 2px #ffffff solid;
}

.geometry1:hover,
.geometry2:hover,
.geometry3:hover,
.geometry4:hover,
.geometry5:hover,
.geometry6:hover,
.geometry7:hover,
.geometry8:hover,
.geometry9:hover,
.geometry10:hover,
.geometry11:hover,
.geometry12:hover,
.geometry13:hover,
.geometry14:hover,
.geometry15:hover {
  border: 2px #6aa4f5 solid;
}

.geometry1:active,
.geometry2:active,
.geometry3:active,
.geometry4:active,
.geometry5:active,
.geometry6:active,
.geometry7:active,
.geometry8:active,
.geometry9:active,
.geometry10:active,
.geometry11:active,
.geometry12:active,
.geometry13:active,
.geometry14:active,
.geometry15:active {
  border: 2px #426b9f solid;
}

.geometry1 .on,
.geometry2 .on,
.geometry3 .on,
.geometry4 .on,
.geometry5 .on,
.geometry6 .on,
.geometry7 .on,
.geometry8 .on,
.geometry9 .on,
.geometry10 .on,
.geometry11 .on,
.geometry12 .on,
.geometry13 .on,
.geometry14 .on,
.geometry15 .on {
  border: 2px #2b78e4 solid;
}

.geometry1 {
  background-position: 0 0;
}
.geometry2 {
  background-position: 0 -32px;
}
.geometry3 {
  background-position: 0 -64px;
}
.geometry4 {
  background-position: 0 -96px;
}
.geometry5 {
  background-position: 0 -128px;
}
.geometry6 {
  background-position: 0 -160px;
}
.geometry7 {
  background-position: 0 -192px;
}
.geometry8 {
  background-position: 0 -224px;
}
.geometry9 {
  background-position: 0 -256px;
}
.geometry10 {
  background-position: 0 -288px;
}
.geometry11 {
  background-position: 0 -320px;
}
.geometry12 {
  background-position: 0 -352px;
}
.geometry13 {
  background-position: 0 -384px;
}
.geometry14 {
  background-position: 0 -416px;
}
.geometry15 {
  background-position: 0 -448px;
}

.extrude0,
.extrude1,
.extrude2 {
  width: 64px;
  height: 64px;
  border-radius: 7px;
  background-color: white;
  border: 2px #ffffff solid;
}

.extrude0:hover,
.extrude1:hover,
.extrude2:hover {
  border: 2px #6aa4f5 solid;
}

.extrude0:active,
.extrude1:active,
.extrude2:active {
  border: 2px #426b9f solid;
}

.extrude0 .on,
.extrude1 .on,
.extrude2 .on {
  border: 2px #2b78e4 solid;
}

.extrude0 {
  background: transparent url(images/picto_extrusion64.png) 0 0 no-repeat;
}
.extrude1 {
  background: transparent url(images/picto_nivgris64.png) 0 0 no-repeat;
}
.extrude2 {
  background: transparent url(images/picto_mosaique64.png) 0 0 no-repeat;
}

.extrudetext1,
.extrudetext2,
.extrudetext3 {
  width: 64px;
  height: 64px;
  border-radius: 7px;
  background-color: white;
  border: 2px #ffffff solid;
}

.extrudetext1:hover,
.extrudetext2:hover,
.extrudetext3:hover {
  border: 2px #6aa4f5 solid;
}

.extrudetext1:active,
.extrudetext2:active,
.extrudetext3:active {
  border: 2px #426b9f solid;
}

.extrudetext1 .on,
.extrudetext2 .on,
.extrudetext3 .on {
  border: 2px #2b78e4 solid;
}

.extrudetext1 {
  background: transparent url(images/texte_extrusion64.png) 0 0 no-repeat;
}
.extrudetext2 {
  background: transparent url(images/texte_gravure64.png) 0 0 no-repeat;
}
.extrudetext3 {
  background: transparent url(images/texte_extrusion_support64.png) 0 0
    no-repeat;
}

.next-arrow,
.previous-arrow,
.arrow-disabled {
  background: transparent url(images/arrows.png) 0 0 no-repeat;
  background-position: 0 -64px;
  width: 32px;
  height: 32px;
  border-radius: 7px;
  background-color: white;
  border: 2px transparent solid;
}

.next-arrow-small,
.previous-arrow-small,
.arrow-disabled-small,
.leave-nav-small {
  background: transparent url(images/arrows_small.png) 0 0 no-repeat;
  background-position: 0 -48px;
  width: 16px;
  height: 16px;
  border-radius: 5px;
  background-color: white;
  border: 2px transparent solid;
}

.button3d {
  height: 39px;
  width: 47px;
  background: url(images/vue_3D_f.png) no-repeat;
  border: solid 2px #aaa;
  color: white;
}

.button3d:hover {
  border-color: black;
}

img.item {
  border: solid 1px #ccc;
}

img.item:hover {
  border: solid 1px black;
}

.no-image img {
  display: none;
}

.next-arrow {
  background-position: 0 -32px;
}
.previous-arrow {
  background-position: 0 0px;
}
/* this coordinate does not exist in the file arrows.png, this is intented to display... nothing*/
.arrow-disabled {
  background-position: 0 -64px;
}

.next-arrow-small {
  background-position: 0 -16px;
}
.previous-arrow-small {
  background-position: 0 0px;
}
.arrow-disabled-small {
  background-position: 0 -32px;
}
.leave-nav-small {
  background-position: 0 -48px;
}

.done {
  background: transparent url(images/done.png) 0 0 no-repeat;
  width: 64px;
  height: 64px;
}

.beta-sticker {
  background: #49e;
  border-radius: 3px;
  color: white;
  display: inline;
  font-weight: 700;
  padding: 3px 5px;
  text-shadow: none;
}

.small-menu-beta {
  font-size: 10px;
  font-weight: 400;
  margin-left: 5px;
  padding: 2px 3px;
  position: absolute;
  text-shadow: none;
}

/*   *** Hints, Warnings, Errors ***   */
i {
  margin: 0 3px 0 0;
}
li.error,
li.hint,
li.warning,
span.error,
span.hint,
span.warning {
  display: inline-block;
  list-style: none;
}

.hint {
  border: 1px solid #ccc;
  background-color: #ffd;
}

.icon-hint:before,
.hint:before {
  font-family: "sculpteo-icons" !important;
  content: "H ";
}

.warning {
  background-color: #ff9 !important;
  border: solid 1px #ea5050;
  color: #111 !important;
}
.warning-orange {
  background-color: rgb(248, 164, 9) !important;
  border: solid 1px #ea5050;
  color: #111 !important;
}

.icon-warning:before,
.warning:before {
  font-family: "sculpteo-icons" !important;
  content: "W ";
}
.warning-orange:before {
  font-family: "sculpteo-icons" !important;
  content: "W ";
}

.error,
.errormsg,
#errorMsg li,
.errorlist--tools li,
.errorlist li {
  background-color: #ea5050 !important;
  border: 1px solid #555;
  color: white !important;
}

.icon-error:before,
.error:before,
.errormsg:before,
#errorMsg li:before,
.errorlist li:before {
  font-family: "sculpteo-icons" !important;
  content: "E ";
  text-shadow: 0 0 3px #000;
}

.error a,
#errorMsg li a,
.errorlist li a {
  text-decoration: underline;
  color: white !important;
}

.error,
.errormsg,
#errorMsg li,
.errorlist li,
.errorlist--tools li,
.hint,
.warning {
  background-clip: border-box;
  border-radius: 2px;
  cursor: default;
  margin: 1px 0;
  padding: 4px 5px !important;
  -ms-background-clip: border-box;
}

.import-log > .icon-hint:before {
  color: #111;
  text-shadow: none;
}

.import-log > .icon-warning:before {
  color: #111;
  text-shadow: none;
}

.import-log > .icon-error:before {
  color: #ea5050;
  text-shadow: none;
}

.detail-hint-wrapper {
  position: relative;
  display: inline-block;
}

.category_tree ul,
.category_tree li {
  clear: both;
}
.category_tree li .branch {
  display: block;
  float: left;
  min-height: 12px;
  padding: 1px 5px 3px 8px;
  margin: 1px;
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
  background-color: #eee;
  color: black;
  cursor: pointer;
}
.category_tree li.noChild .branch {
  border-radius: 7px;
}

.category_tree li .triangle {
  background: transparent url(images/triangle.12.png) 0 -4px no-repeat;
  display: block;
  float: left;
  padding: 1px 2px 3px 8px;
  margin: 1px;
  margin-right: 0;
  height: 13px;
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
  background-color: #eee;
  color: black;
  width: 11px;
  cursor: pointer;
}

.category_tree li .branch input {
  margin: 0;
  padding: 0;
}

.category_tree li.selected .triangle {
  background-position: -24px -4px;
}

.category_tree li .triangle.opened,
.category_tree li .triangle.opened:hover {
  background-position: 0 -28px;
}
.category_tree li.selected .triangle.opened {
  background-position: -24px -28px;
}

.category_tree li .branch:hover,
.category_tree li .triangle:hover {
  color: black;
  background-color: #fbfcc6;
  text-decoration: none;
}
.category_tree li.selected > .branch,
.category_tree li.selected > .triangle {
  color: white;
  background-color: #1c85e0 !important;
}
.category_tree ul ul li {
  margin-left: 22px;
}

ul.bullets {
  padding-left: 8px;
  list-style-type: disc;
  list-style-position: inside;
}

ol.toc_categories > li {
  padding-bottom: 32px;
}

ol.toc_questions > li {
  padding-left: 30px;
}

ol.toc_categories,
.faq ol.categories {
  list-style-type: upper-roman;
}

.faq li.category {
  margin-top: 32px;
  padding-top: 4px;
  border-top: 1px solid black;
}

.faq li.category h2 {
  display: inline;
}

.faq li.question {
  margin-top: 24px;
  border: 2px transparent solid;
  border-radius: 7px;
  padding: 12px;
  background-color: #ffffff;
}

.faq li.question h3 {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  letter-spacing: 0;
  font-weight: bold;
  display: inline;
}

ul.step-tabs-688 {
  background: url(images/4step-tabs.688.png) 0 0 no-repeat transparent;
  display: block;
  width: 688px;
  height: 24px;
}
ul .step-tabs-688.position-2 {
  background-position: 0 -24px;
}
ul .step-tabs-688.position-3 {
  background-position: 0 -48px;
}
ul .step-tabs-688.position-4 {
  background-position: 0 -72px;
}

ul.step-tabs-448 {
  background: url(images/3step-tabs.448.png) 0 0 no-repeat transparent;
  display: block;
  width: 448px;
  height: 24px;
}
ul.step-tabs-448 li {
  width: 150px;
  font-size: 12px;
  color: black;
  line-height: 24px;
  float: left;
  display: block;
  text-align: center;
}
ul .step-tabs-448 li.first {
  width: 148px;
}

ul .step-tabs-448.position-2 {
  background-position: 0 -24px;
}
ul .step-tabs-448.position-3 {
  background-position: 0 -48px;
}
span .required {
  font-weight: bold;
  color: #ea5555;
}

.warning16 {
  background: url(images/warning.16.png) 0 0 no-repeat transparent;
  margin-top: 0;
  padding-top: 0;
  padding-left: 20px;
}

code,
.code {
  font-family: "AmericanTypewriter", "Courier", monospace;
  font-weight: bold;
  font-size: 13px;
}

.help48 {
  background: url(images/help.48.png) 0 0 no-repeat;
  height: 48px;
  padding-left: 60px;
}

.tooltip_color_48 {
  background: url(images/texture_mat_48.png) 0 0 no-repeat;
  height: 48px;
  padding-left: 60px;
}

.tooltip_orientation_48 {
  background: url(images/picto_orientable_48.png) 0 0 no-repeat;
  height: 48px;
  padding-left: 60px;
}

.tooltip_upload_48 {
  background: url(images/picto_transfert_48_v2.png) 0 0 no-repeat;
  height: 48px;
  padding-left: 60px;
}

.tooltip_upload_64 {
  background: url(images/picto_transfert_64_v2.png) 0 0 no-repeat;
  height: 64px;
  padding-left: 80px;
}

.login-notification {
  /*background-color: #201D18;*/
  /*background : white url(images/fond_block_wide_light.png) top no-repeat;*/
  background: white;
  color: black;
  padding: 20px;
  border: 1px dotted #aaa;
}

.login-notification span {
  /*color: #ccc;*/
  color: black;
}

.terms p {
  text-indent: 1em;
  text-align: justify;
}

.terms ol {
  list-style-type: upper-roman;
  margin-top: 24px;
}

.terms li {
  text-indent: 0.5em;
  text-align: justify;
  margin-top: 12px;
  font-size: 200%;
}

.terms li p {
  text-indent: 1em;
  text-align: justify;
  margin-top: 12px;
  font-size: 50%;
}

.terms ol li ol {
  margin-top: 0;
  list-style-type: lower-roman;
}

.terms ol li ol li {
  list-style-type: lower-roman;
  font-size: 50%;
}

.terms pre {
  margin: 10px 0 10px 20px;
  font-size: 12px;
  padding: 10px;
  border: 1px solid black;
  width: 50%;
}

/* >> Jquery tooltip plugin */
#tooltip {
  position: absolute;
  z-index: 10000;
  border: 1px solid #111 !important;
  background-color: #eee;
  font-size: 1em;
  padding: 6px;
  border-radius: 3px;
  box-shadow: none;
  text-align: left;
}
#tooltip h3,
#tooltip div {
  margin: 0;
}
/* << Jquery tooltip plugin */

dl.shipping {
  padding: 1em;
  margin: 0;
}

.shipping dt {
  font-weight: bold;
  margin: 0;
  padding: 0;
}

.shipping dd {
  margin: 0 0 0 2em;
  padding: 0 0 0.5em 0.5em;
}

.gray_block {
  background: #444043 url(images/fond_block2.png) bottom right no-repeat;
  color: white;
}

.gray_block strong {
  color: white;
}

.gray_block a {
  color: white;
}

.tooltip_block {
  background: #696669 url(images/fond_tooltip2.png) bottom right no-repeat;
  color: white;
}

.tooltip_block strong {
  color: white;
}

.tooltip_block a {
  color: white;
}

.tag-1 {
  font-size: 8pt;
}

.tag-2 {
  font-size: 10pt;
}

.tag-3 {
  font-size: 12pt;
}

.tag-4 {
  font-size: 14pt;
}

.tag-5 {
  font-size: 16pt;
}

.tag-6 {
  font-size: 18pt;
}

.checkoutform input[type="password"],
.checkoutform input[type="button"],
.checkoutform input[type="file"] {
  width: 130px;
  margin-top: 16px;
  height: 24px;
  background: #3d81e0 url(images/selection.png) 0 -33px repeat-x;
  border-radius: 7px;
  border: 2px #6aa4f5 solid;
  padding-top: 3px;
  text-align: center;
  cursor: pointer;
  color: white;
  font-size: 10px;
  font-weight: bold;
}

.checkoutform input[type="submit"] {
  background-image: url("images/button-160-blue.png");
  background-repeat: no-repeat;
  background-color: transparent;
  background-position: 0 0;
  padding: 0;
  margin: 0;
  width: 160px;
  height: 26px;
  display: inline-block;
  text-align: center;
  line-height: 26px;
  text-decoration: none;
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  color: white !important;
  text-shadow: rgba(255, 255, 255, 0.5) 0 1px 0, rgba(0, 0, 0, 0.3) 0 -1px 0;
  border: 0 !important;
  outline: none;
}

.checkoutform input[type="submit"]:hover {
  background-position: 0 -26px;
  cursor: pointer;
  text-decoration: none;
}
.checkoutform input[type="submit"]:active {
  background-position: 0 -52px;
  color: #eee;
  cursor: pointer;
}

.checkoutform input[type="radio"],
.checkoutform input[type="checkbox"] {
  margin-top: 16px;
  padding-top: 3px;
  text-align: center;
  cursor: pointer;
}

.underline {
  text-decoration: underline;
}

.strike {
  text-decoration: line-through;
}
/* small gallery of designs in tabs */
ul.small-gallery-tab {
  float: left;
  list-style: none;
  padding: 20px;
}
ul.small-gallery-tab li {
  margin: 4px;
  float: left;
  position: relative;
  width: 88px;
  height: 68px;
}
ul.small-gallery-tab li img {
  width: 80px;
  height: 60px;
  position: absolute;
  left: 4px;
  top: 4px;
  cursor: pointer;
  -ms-interpolation-mode: bicubic;
}

.smallgallery-container ul.text {
  list-style-type: disc;
  list-style-position: inside;
  position: relative;
}
.smallgallery-container ul.text > li {
  cursor: pointer;
  padding-left: 8px;
}
.smallgallery-container ul.text > li:hover {
  background-color: black;
  opacity: 0.8;
  color: white;
}
.small-gallery-even {
  background-color: #d5edf5;
}
.small-gallery-odd {
  background-color: #b9d7ed;
}

.header_block_left {
  background: white url(images/fond_block_header_gauche.png) left no-repeat;
  padding-left: 35px;
  height: 76px;
}

/* new style homepage v2 */

#main_upload_button {
  position: absolute;
  top: -40px;
  left: 330px;
  width: 280px;
  height: 81px;
  background: transparent url(images/fond_bouton_upload.png) top left no-repeat;
}

a.designed_upload_button,
a.designed_upload_button:hover {
  background: transparent url(images/bouton_upload2.png) top left no-repeat;
  margin-top: 14px;
  margin-left: 3px;
  margin-right: 6px;
  width: 265px;
  height: 66px;
  display: block;
  text-decoration: none !important;
}

.block-left {
  background: transparent url(images/fond_block_gauche.png) top left no-repeat;
}

.block-right {
  background: transparent url(images/fond_block_droit.png) top right no-repeat;
}

.block-wide {
  background: transparent url(images/fond_block_wide_light.png) top no-repeat;
}

.little-block-left {
  background: transparent url(images/fond_little_block_left.png) top left
    no-repeat;
}

.little-block-right {
  background: transparent url(images/fond_little_block_right.png) top right
    no-repeat;
}

.block-wide-bottom {
  background: transparent url(images/fond_block_wide_bottom3.png) bottom
    no-repeat;
  /*background:black url(images/slide_bg.png) top no-repeat;*/
}

.block-tooltip {
  background: #f8f8fb url(images/fond_block_tooltip2.png) top right no-repeat;
  border: solid 1px #cbcfd3;
}

.block-customize-pub {
  background: #f8f8fb url(images/picto_personnalisation_modele256_light.png) -30px -30px
    no-repeat;
  /*border : solid 1px #cbcfd3;*/
}

.block-full {
  background: #f8f8fb url(images/fond_block_full.png) top right no-repeat;
  border: solid 1px #cbcfd3;
}

.block-full-light {
  background: #f8f8f8;
  border-radius: 7px;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.2);
}

.block-full-light h2 {
  color: #000;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25), 0 1px 0 rgba(255, 255, 255, 0.25);
  text-align: center;
  font-size: 22px;
  padding-bottom: 4px;
}

.block-full-empty {
  border: solid 1px #ccc;
  border-radius: 7px;
}

.block-step1 {
  background: transparent url(images/block_step1_4.png) top left no-repeat;
}

.block-step2 {
  background: transparent url(images/block_step2_4.png) top left no-repeat;
}

.block-step3 {
  background: transparent url(images/block_step3_4.png) top left no-repeat;
}

.big_title {
  font-weight: bold;
  text-shadow: none;
  font-size: 22pt;
  line-height: 22pt;
  color: #010101;
  min-height: 30px;
}

.big_title strong {
  font-weight: bold;
  font-size: 36pt;
  line-height: 36pt;
}

.page_title {
  position: relative;
  padding-top: 8px;
  padding-bottom: 8px;
}

.page_title h1 {
  font-weight: bold;
  text-shadow: none;
  font-size: 22pt;
  line-height: 22pt;
  color: #010101;
  min-height: 30px;
  float: left;
}

.page_title hr {
  border: 0;
  border-top: #aaa 1px dotted;
}

.page_title br {
  clear: both;
}

.page_title p {
  font-size: 10pt;
  -webkit-font-smoothing: subpixel-antialiased;
  letter-spacing: 0;
  color: #7b7b7b;
  min-height: 12px;
}

.page_title a {
  color: #7c7c7c;
  text-decoration: underline;
}

.page_title h4 {
  font-size: 13px;
  color: #898989;
  font-weight: normal;
  line-height: normal;
  text-align: left;
  width: 175px;
  height: 18px;
  overflow: hidden;
  white-space: nowrap;
}

.page_title h4 strong {
  color: black;
  font-weight: normal;
}

.color_title {
  color: #7c7c7c;
}

.color_title_dark {
  color: #424242;
}

.arial_11pt {
  font-size: 11pt;
  font-weight: normal;
}

.medium_title {
  font-size: 12pt;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 4px;
  color: #333;
  /*line-height:24px;*/
}

.pointer {
  cursor: pointer;
}

.cursor-zoom-in {
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
}
.low_height {
  height: 5px;
}

.height_30px {
  height: 30px;
}

#frame {
  position: relative;
  z-index: 1000;
}

.viewer-navigation {
  width: 32px;
  height: 32px;
  background-image: url(images/viewer-navigation.png);
}

.toolbox-navigation-state-hover {
  background-image: url(images/viewer-navigation-hover.png);
}

.toolbox-navigation-move-s {
  background-position: 0 0;
}

.toolbox-navigation-move-w {
  background-position: -32px 0;
}

.toolbox-navigation-move-e {
  background-position: -64px 0;
}

.toolbox-navigation-move-n {
  background-position: -96px 0;
}

.toolbox-navigation-rotate-s {
  background-position: -128px 0;
}

.toolbox-navigation-rotate-w {
  background-position: -160px 0;
}

.toolbox-navigation-rotate-e {
  background-position: -192px 0;
}

.toolbox-navigation-rotate-n {
  background-position: -224px 0;
}

.toolbox-navigation-zoom-plus {
  background-position: -256px 0;
}

.toolbox-navigation-zoom-minus {
  background-position: -288px 0;
}

.right_col_community_pages {
  width: 285px;
  background: url(images/fond_col_droite_page_cible.png) repeat-y;
  float: right;
}

.main_col_community_pages {
  width: 697px;
  background: white;
}

.modelism_title {
  width: 100%;
  color: #424242;
  font-size: 17pt;
  font-weight: normal;
  letter-spacing: -1px;
  line-height: normal;
  text-align: center;
}

.modelism_title strong {
  display: block;
  margin-bottom: 70px;
  font-weight: normal;
  font-size: 36pt;
  color: #424242;
  line-height: 36pt;
  letter-spacing: -2px;
}

.bottom_modelism_page_block_title {
  background: url(images/fond_blanc_transparent.png) repeat;
  width: 310px;
  height: 140px;
  float: right;
  text-align: right;
  margin-right: 13px;
  margin-top: 16px;
  padding: 5px;
  padding-top: 10px;
  color: #424242;
  font-size: 15pt;
  font-weight: normal;
  letter-spacing: -1px;
}

.community-bullet-list {
  margin-top: 20px;
  float: left;
  background: url(images/fleche_liste.png) left top no-repeat;
  text-align: left;
  padding-left: 30px;
  padding-right: 10px;
}

.community-bullet-list strong {
  color: #ccc;
  font-size: 17pt;
  font-weight: normal;
  letter-spacing: -1px;
}

.community-bullet-list p {
  margin-top: 8px;
  color: white;
  font-size: 8pt;
  font-weight: normal;
}

.community_light_col_block {
  float: left;
  margin-top: 24px;
  padding-left: 4px;
  padding-right: 10px;
  padding-bottom: 16px;
  width: 285px;
  background: url(images/fond_col_droite_light_page_cible.png) repeat-y;
}

.community_light_col_block_title {
  padding-top: 8px;
  margin-left: 12px;
  color: white;
  font-size: 13pt;
  font-weight: normal;
  letter-spacing: -1px;
}

#community_upload_button {
  padding-top: 1px;
  width: 280px;
  height: 81px;
  background: transparent url(images/fond_bouton_upload_opaque.png) top left
    no-repeat;
}

.community_light_col_block p {
  margin-left: 12px;
  margin-top: 8px;
  color: white;
  font-size: 8pt;
  font-weight: normal;
}

.community_light_col_block a {
  text-decoration: underline;
  color: white;
}

.community_tooltip_col_block {
  width: 285px;
  background: url(images/fond_tooltip_page_cible.png) 150px 0 no-repeat;
  padding: 8px;
}

.community_tooltip_col_block span {
  padding-top: 8px;
  margin-left: 8px;
  color: #ccc;
  font-size: 13pt;
  font-weight: normal;
  letter-spacing: -1px;
}

.community_tooltip_col_block ul {
  margin: 8px;
  list-style-type: square;
  list-style-position: outside;
  margin-left: 20px;
}

.community_tooltip_col_block li {
  margin-top: 8px;
  color: white;
  font-size: 8pt;
  font-weight: normal;
}

.community_tooltip_col_block li a {
  color: white;
  text-decoration: underline;
}

.community_right_col_end {
  width: 285px;
  height: 18px;
  background: transparent url(images/community_right_col_end.png) no-repeat;
}

.right_col_community_pages_absolute {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 20;
}

.community_pages_wide_content {
  position: relative;
  width: 100%;
  min-height: 1000px;
}

.main-figurines-content {
  position: relative;
  width: 100%;
  height: 470px;
}

.main-figurines-content img {
  position: absolute;
}

.figurines-content {
  position: relative;
  width: 100%;
  height: 300px;
}

.figurines-content img {
  position: absolute;
}

.figurines-content-title {
  position: relative;
  padding-top: 16px;
  margin-left: 16px;
  z-index: 10;
}

.figurines_title {
  width: 100%;
  color: #424242;
  font-size: 20pt;
  font-weight: normal;
  letter-spacing: -1px;
  line-height: 26px;
}

.figurines_title span {
  font-size: 22pt;
  color: #424242;
  font-weight: normal;
  letter-spacing: -2px;
  line-height: 26px;
}

.figurines_title strong {
  display: block;
  margin-bottom: 320px;
  font-weight: normal;
  font-size: 36pt;
  color: #424242;
  line-height: 36pt;
  letter-spacing: -2px;
}

.figurines_secondary_title {
  color: #424242;
  font-size: 14pt;
  font-weight: normal;
  letter-spacing: -1px;
  line-height: 26px;
}

.figurines_secondary_title strong {
  display: block;
  font-weight: normal;
  font-size: 22pt;
  color: #424242;
  letter-spacing: -2px;
  line-height: 26px;
}

.line-height-16 {
  line-height: 16px;
}

.line-height-32 {
  line-height: 32px;
}

.line-height-48 {
  line-height: 48px;
}

.customize_3Dview_block {
  border-radius: 7px;
  margin-top: 8px;
  padding: 2px;
  border: solid 1px #aaa;
  max-width: 265px;
  background: white url(images/customize_3Dlink_bg.png) bottom right no-repeat;
  cursor: pointer;
}

.customize_3Dview_block:hover {
  border: solid 1px black;
  text-decoration: underline;
}

.plus_button {
  height: 16px;
  width: 16px;
  background: transparent url(images/plus.png) no-repeat;
  display: block;
}

.moins_button {
  height: 16px;
  width: 16px;
  background: transparent url(images/moins.png) no-repeat;
  display: block;
}

.job-state-1 {
  color: green;
}

.job-state-2 {
  color: red;
}

.job-state-3 {
  color: black;
}

.sidebar {
  width: 100%;
  border-left: #ccc;
  margin-right: 0;
  height: 100%;
}

.sidebar .parameters {
  background: #d2f1e9;
}

.uppercase-text {
  font-size: 8pt;
  color: white;
  text-transform: uppercase;
  text-decoration: none;
}

.uppercase-text strong {
  font-size: 10pt;
  font-weight: normal;
  color: white;
}

.lvl3_subnav {
  margin-top: 0;
  margin-left: 32px;
  padding-right: 16px;
  text-align: left;
  font-size: 13px;
  color: white;
  /*text-transform: uppercase;*/
  text-decoration: none;
}

.lvl3_subnav a {
  display: block;
  text-align: left;
  color: #1c97ec;
}

.lvl3_subnav a:hover {
  color: #1c85e0;
  text-decoration: none;
  /*	color:#6b548c;
	background:white;*/
}

.italic_link {
  font-weight: bold;
  font-style: italic;
  text-decoration: underline;
  color: black;
}

.important_link {
  /*color:#5c6eb8;*/
  background: transparent url(images/fleche_lien.png) left no-repeat;
  padding-left: 19px;
  color: #1c85e0;
}

ul.arrowed {
  list-style: square inside url("images/fleche_lien.png");
}

.important_link:hover {
  text-decoration: underline;
}

.important_link:visited {
  color: #5c6eb8;
}

.visitable {
  font-size: 13px;
  color: white;
  color: #1d97ec;
  text-decoration: none;
}

.visitable:hover {
  color: #1c85e0;
  text-decoration: none;
}

.visitable:visited {
  color: #5c6eb8;
}

.faq_button:visited {
  color: #5c6eb8;
}

/* Styles FEATURED DESIGN Gallery */

.designs_main_view {
  width: 400px;
  height: 400px;
  border: solid 1px #acacac;
  outline: solid 3px #dbdbdb;
  -moz-outline-radius: 3px;
  float: left;
  margin: 3px;
  margin-top: 16px;
  margin-right: 8px;
  overflow: hidden;
  /*cursor:pointer;*/
}
.designs_title {
  font-weight: bold;
  font-size: 22pt;
  line-height: 22pt;
  color: #010101;
  min-height: 30px;
}
.designs_designer {
  font-size: 13px;
  -webkit-font-smoothing: subpixel-antialiased;
  letter-spacing: 0;
  color: #7b7b7b;
  min-height: 12px;
}
.designs_designer a {
  color: #7b7b7b;
  text-decoration: none;
}
.designs_description {
  -webkit-font-smoothing: subpixel-antialiased;
}
.designs_description p {
  -webkit-font-smoothing: subpixel-antialiased;
}
.designs_other_view {
  width: 340px;
  height: 49px;
  margin-top: 16px;
  /*border-bottom:solid 1px #4f4f4f;*/
}

.button_3D_view {
  height: 48px;
  width: 48px;
  display: block;
  background: transparent url(images/vue_3D_button50.png) top left no-repeat;
  background-position: 0 0;
  float: left;
  margin-right: 8px;
  margin-left: 3px;
  border: solid 1px #acacac;
  outline: solid 3px #dbdbdb;
  -moz-outline-radius: 3px;
  text-align: center;
  color: white;
  font-size: 8pt;
  line-height: 24px;
}
.button_3D_view:hover {
  background-position: -50px 0;
  border: solid 1px #5d5d5d;
  text-decoration: none;
}
.button_3D_view .selected {
  border: solid 1px #5d5d5d;
}

.designs_other_view img {
  cursor: pointer;
  float: left;
  margin-right: 8px;
  border: solid 1px #acacac;
  outline: solid 3px #dbdbdb;
  -moz-outline-radius: 3px;
  height: 48px;
  width: 48px;
  overflow: hidden;
}

.designs_other_view img.selected {
  border: solid 1px #5d5d5d;
}
.designs_other_view img:hover {
  border: solid 1px #5d5d5d;
}
.designs_price {
  text-align: right;
  font-weight: bold;
  font-size: 40pt;
  line-height: 40pt;
  letter-spacing: -3px;
  color: #010101;
}

.designs_price p {
  margin-top: 8px;
  font-size: 9pt;
  line-height: 9pt;
  font-weight: normal;
  color: #010101;
}

.designs_links {
  float: right;
  /*min-height:60px;*/
}
.designs_links a {
  float: right;
  -webkit-font-smoothing: subpixel-antialiased;
  font-size: 11pt;
  display: block;
  margin-bottom: 8px;
}

.share_design_link {
  color: #1c85e0;
  cursor: pointer;
}

/* Fin des styles FEATURED DESIGN Gallery */

.product-selection {
  cursor: pointer;
}

.basket_bull a:hover {
  text-decoration: none;
}

.basket_bull {
  float: left;
  font-size: 8pt;
}

.filled_basket {
  border-radius: 7px;
  padding: 6px;
  cursor: pointer;
}

.filled_basket:hover {
  background: #f8f8f8;
}

.empty_basket {
  padding: 4px;
}

.square-list {
  margin: 8px;
  list-style-type: square;
  list-style-position: outside;
  margin-left: 20px;
}

.square-list li {
  margin-top: 8px;
  font-size: 11pt;
  /*font-weight:bold;*/
  font-style: italic;
}

.box-link {
  border: solid 1px #ccc;
  text-decoration: none;
}

.box-link:hover {
  text-decoration: none;
  border: solid 1px #aaa;
}

.add-param-button,
.del-param-button,
.add-logo-button,
.del-logo-button,
.add-font-button,
.del-font-button {
  margin-top: 4px;
  margin-left: 4px;
  width: 16px;
  height: 16px;
  background-repeat: no-repeat;
  background-position: 0 0;
}

.add-param-button,
.add-logo-button,
.add-font-button {
  background-image: url(images/plus.png);
}

.del-param-button,
.del-logo-button,
.del-font-button {
  background-image: url(images/moins.png);
}

.figure-price-withbackround_transp {
  background: #3d81e0; /* solid color */
  background: rgba(61, 129, 224, 0.75); /* transparent color */
  zoom: 1; /* required for the filters */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#c03d81e0, endColorstr=#c03d81e0); /* IE5.5-7 */
  -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr=#c03d81e0, endColorstr=#c03d81e0)"; /* IE8 */
  color: white !important;
  border: 2px #6aa4f5 solid;
}

.figure-price-withbackround {
  background: #3d81e0 url(images/selection.png) 0 -33px repeat-x;
  color: white !important;
  border: 2px #6aa4f5 solid;
}

.figure-price-button {
  color: white;
  font-size: 14px;
}
.figure-price-button .price {
  color: white;
  font-size: 14px;
}
.figure-price-button .was {
  color: white;
  text-decoration: line-through;
  font-size: 13px;
}
.figure-price-button .new {
  color: white;
  font-size: 14px;
  font-weight: bold;
}

.figure-price {
  color: black;
  font-size: 14px;
}
.figure-price .price {
  color: #741111;
  font-size: 14px;
}
.figure-price .was {
  color: #444444;
  text-decoration: line-through;
  font-size: 13px;
}
.figure-price .new {
  color: #741111;
  font-size: 14px;
  font-weight: bold;
}

.big-price .was {
  color: #7b7b7b;
  text-decoration: line-through;
  font-size: 17pt;
}
.big-price .new {
  color: #741111;
  font-size: 17pt;
  font-weight: bold;
}

.hiddenuploadwrapper {
  overflow: hidden;
  position: absolute;
  left: 0;
  top: 0;
  margin: 0;
  padding: 0;
  font-size: 0;
  line-height: 0;
  border: none;
  -moz-opacity: 0;
  opacity: 0;
  z-index: 2;
  width: 0;
  height: 0;
  cursor: pointer;
  background-color: red;
}
.hiddenuploadwrapper input {
  border: none;
  margin: 0;
  padding: 0;
  position: absolute;
  text-align: right;
  right: 1px;
  top: -1px;
  font-size: 128px;
  z-index: 2;
  cursor: pointer;
}

ul.flow-layout > li {
  min-height: 20px;
  margin-right: 6px;
  /*margin-bottom:10px;*/
  display: inline-block;
  position: relative;
}

.hover_block {
  width: 610px;
  height: 200px;
  overflow: hidden;
  border: solid 1px #dedede;
  outline: solid 3px #f5f5f5;
  float: left;
  margin: 8px;
  padding: 10px;
  cursor: pointer;
  position: relative;
}

.hover_block:hover {
  border: solid 1px #aaa;
  background-color: #fdfdfd;
}

.hover_block a:hover {
  text-decoration: none;
}

.hover_block_left {
  float: left;
  margin-left: 8px;
  width: 446px;
  height: 200px;
}

.hover_block .medium_title {
  font-size: 13pt;
  color: black;
  font-weight: bold;
  line-height: normal;
  text-transform: none !important;
  margin-bottom: 0;
  text-align: left;
  margin-top: 4px;
}

.tuto_descr {
  font-size: 13px;
  color: #898989;
  -webkit-font-smoothing: subpixel-antialiased;
  font-weight: normal;
  line-height: normal;
  text-align: left;
  margin-top: 8px;
  position: relative;
}

.hover_block_link {
  position: absolute;
  bottom: 12px;
  right: 12px;
}

.dark-button {
  background: #222222 url(images/dark_button_bg.jpg) top left repeat-x;
  border: solid 1px #222222;
}

.dark-button:hover {
  background: #2e2e2e url(images/dark_button_bg_hover.jpg) top left repeat-x;
  text-decoration: none;
  border: solid 1px #2e2e2e;
}

.shop-sidebar-title {
  padding: 8px;
  padding-top: 30px;
  font-size: 11px;
  text-transform: uppercase;
  font-style: italic;
  font-weight: bold;
}

.shop-title {
  padding-top: 10px;
  font-size: 20pt;
  color: #1c85e0;
  font-weight: normal;
  line-height: normal;
  margin-bottom: 0;
}

.shop-tree-line {
  padding-top: 8px;
  padding-bottom: 8px;
  text-transform: uppercase;
  font-size: 7pt;
  text-decoration: none;
  color: #424242;
}

.shop-tree-line a {
  text-transform: uppercase;
  font-size: 7pt;
  text-decoration: none;
  color: #1c85e0;
}

.shop-tree-line a:hover {
  text-decoration: underline;
}

.designer_shop {
  float: left;
  height: 100px;
  width: 311px;
  background-color: transparent;
  padding: 8px;
  margin-bottom: 32px;
  cursor: pointer;
}

.designer_shop_bglightgrey {
  background-color: #fbfbfb;
}

.designer_shop:hover {
  background-color: #f1f1f1;
}

.diagnostic_caption {
  width: 100%;
  margin-top: 2px;
  margin-bottom: 2px;
}

.diagnostic_caption_color {
  float: left;
  margin-right: 0.5em;
  width: 15px;
  height: 15px;
}

.diagnostic_caption_text {
  float: left;
}

.diagnostic_caption_checkbox {
  float: right;
  margin-left: 8px;
  margin-right: 8px;
  margin-top: -2px;
}

.diagnostic_non_manifold_edges .diagnostic_caption_color {
  background-color: #ff0000;
}

.diagnostic_non_manifold_vertices .diagnostic_caption_color {
  background-color: #ff8000;
}

.diagnostic_border_edges .diagnostic_caption_color {
  background-color: #ffff00;
}

.diagnostic_flipped_faces .diagnostic_caption_color {
  background-color: #800080;
}

.diagnostic_intersecting_faces .diagnostic_caption_color {
  background-color: #9374fa;
}

.login-block {
  padding-top: 30px;
}

.page-wrapper {
  width: 960px;
  position: relative;
  margin: 0 auto;
  padding: 0 10px;
  clear: both;
}
.page-wrapper-padding {
  padding: 0 10px;
}

.nav-header {
  margin-bottom: 49px;
}

.spinner {
  padding: 60px 0 20px 0;
  text-align: center;
  height: 16px;
  font-size: 16px;
  color: #333;
}

.logo {
  height: 34px;
  padding-top: 0;
  padding-bottom: 16px;
}

.logo div {
  margin: 0;
  padding: 0;
  cursor: pointer;
}

.logo a {
  background: url(images/logo.png) no-repeat top left;
  height: 22px;
  margin-right: 8px;
  margin-top: 2px;
  display: block;
  text-decoration: none;
}
.logo .domain {
  background: url(images/pipe.png) no-repeat 120px 4px;
  padding-left: 128px;
  font-size: 24px;
  line-height: 20px;
  font-weight: normal;
}
.domain-sculpteo .domain {
  color: #00c6f7;
}
.domain-stores .domain {
  color: #4eff7c;
}
.domain-workshops .domain {
  color: #fffaae;
}
.domain-account .domain {
  color: #fead21;
}
.domain-answers .domain {
  color: #f6288e;
}
.domain-pro .domain {
  color: white;
}
.domain-stores .nav-l2 {
  background-color: #1f7be5;
  color: black;
  height: 26px;
}

.section_title {
  font-size: 12pt;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 4px;
  color: #333;
  line-height: 24px;
  text-shadow: none;
}

.section_title span {
  font-size: 9pt;
  font-weight: 300;
  font-style: normal;
  margin-bottom: 4px;
  color: #333;
  line-height: 24px;
  margin-left: 4px;
  text-transform: none;
  letter-spacing: 0;
}

/* 3dviewer */

.info {
  margin-top: 8px;
  margin-bottom: 8px;
}

.info h1 {
  color: black;
  font-weight: bold;
  padding-bottom: 0;
  line-height: 28px;
}

.info p {
  font-size: 9pt;
  color: #7b7b7b;
}

.info a {
  font-size: 9pt;
  color: #7b7b7b;
}

.module-img .button3d {
  height: 40px;
  width: 40px;
  background: url(images/vue_3D_f.png) no-repeat;
  border: solid 1px #dedede;
  outline: solid 3px #f5f5f5;
  color: white;
}

.module-img .images img {
  height: 40px;
  width: 40px;
  border: solid 1px #dedede;
  outline: solid 3px #f5f5f5;
  margin-right: 16px;
  -moz-outline-radius: 3px;
}

._3dView {
  border: solid 1px #dedede;
  outline: solid 3px #f5f5f5;
  -moz-outline-radius: 3px;
}

/* fin 3dviewer */

.search_label {
  font-size: 11pt;
  -webkit-font-smoothing: subpixel-antialiased;
  letter-spacing: 0;
  font-style: normal;
  padding-top: 8px;
  margin-bottom: 12px;
  font-weight: normal;
  color: #333;
  color: rgba(0, 0, 0, 0.8);
  /*text-shadow: 0px 1px white, 0px -1px black;*/
  text-shadow: none;
}

.search_label span {
  font-size: 12pt;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 4px;
  color: #333;
  line-height: 24px;
  text-shadow: none;
}

.title_notification {
  background: #fffbc2;
  border-bottom: solid 1px #181818;
  padding-top: 8px;
  padding-bottom: 8px;
  position: relative;
  width: 100%;
}

.title_notification h5 {
  font-size: 12pt;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0;
  margin-bottom: 4px;
  color: #333;
  line-height: 24px;
  text-shadow: none;
  margin: auto;
}

.title_notification p {
  font-size: 13px;
  -webkit-font-smoothing: subpixel-antialiased;
  letter-spacing: 0;
  color: #7b7b7b;
  min-height: 12px;
  margin: auto;
}

.title_notification a {
  color: #7c7c7c;
  text-decoration: underline;
  margin: auto;
}

input[type="text"].field-button {
  height: 28px;
  margin: 0;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.8);
  border: 0;
  border-radius: 0;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
  width: 160px;
}

input[type="submit"].field-button {
  background: url(images/field-button.png) 0 0 no-repeat;
  width: 36px;
  height: 28px;
  display: inline-block;
  border: 0;
  outline: 0;
  color: white;
}
input[type="submit"].field-button:hover {
  cursor: pointer;
  background-position: 0 -28px;
}
input[type="submit"].field-button:active {
  cursor: pointer;
  background-position: 0 -56px;
}

.link-no-hover:hover {
  text-decoration: none;
}

hr.shadow {
  -moz-background-clip: border;
  -moz-background-inline-policy: continuous;
  -moz-background-origin: padding;
  background: transparent url(images/hr-shadow.png) no-repeat scroll left top;
  border: 0 none;
  clear: both;
  height: 10px;
  margin: 42px auto;
  width: 596px;
}

hr.shadow_thin {
  -moz-background-clip: border;
  -moz-background-inline-policy: continuous;
  -moz-background-origin: padding;
  background: transparent url(images/hr-shadow.png) no-repeat scroll left top;
  border: 0 none;
  clear: both;
  height: 10px;
  margin: 0 auto !important;
  /*margin:42px auto;*/
  width: 596px;
}

.header_shop {
  border-bottom: 1px solid #ccc;
}

.header_shop_spacer {
  margin-top: 49px;
  margin-bottom: -49px;
}

.m100t {
  margin-top: 100px;
}

a .social {
  background: url(images/social.png);
  display: block;
  text-indent: -1000em;
  direction: ltr;
  overflow: hidden;
  float: left;
  width: 16px;
  height: 16px;
  padding: 0;
  margin: 0 4px 0 0;
}
a .social .facebook:hover {
  background-position: 0 -16px;
}
a .social .twitter {
  background-position: -16px 0;
}
a .social .twitter:hover {
  background-position: -16px -16px;
}
a .social .rss {
  background-position: -32px 0;
}
a .social .rss:hover {
  background-position: -32px -16px;
}
a .social .plus {
  background-position: -48px 0;
}
a.social.plus:hover {
  background-position: -48px -16px;
}

.header-gray-bg {
  width: 100%;
  height: 280px;
  background: url(images/header-gray-bg.jpg) repeat-x bottom left;
  position: relative;
  border-top: 1px white solid;
  border-bottom: 1px #999 solid;
  margin-bottom: 24px;
}

.header-gray-bg h1 {
  text-shadow: 0 1px 1px white, 0 -1px 1px rgba(0, 0, 0, 0.1);
}

.global_link {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  background-image: url("images/empty.gif");
}

.over_global_link {
  z-index: 2;
}

.import-log li {
  display: block;
  padding: 6px 0 6px 30px;
  margin: 10px 0 0 0;
  border-radius: 4px;
  -webkit-animation: bounce-background 1s 10, flash-background 1s 2;
  -moz-animation: bounce-background 1s 10, flash-background 1s 2;
  -ms-animation: bounce-background 1s 10, flash-background 1s 2;
  -o-animation: bounce-background 1s 10, flash-background 1s 2;
  animation: bounce-background 1s 10, flash-background 1s 2;
  font-family: inherit !important;
}

.import-log-info {
  background: url(images/info.16.png) 6px 6px no-repeat transparent;
  background-color: #cbdcf6;
  border: 2px #cadcf5 solid;
}

.import-log-warning {
  background: url(images/warning.16.png) 6px 6px no-repeat transparent;
  background-color: #fbfcc6;
  border: 2px #fafc85 solid;
}

.import-log-error {
  background: url(images/error.16.png) 6px 6px no-repeat transparent;
  background-color: #fbdcc6;
  border: 2px #fadc85 solid;
}

.profile table,
.profile caption,
.profile tbody,
.profile tfoot,
.profile thead,
.profile tr,
.profile th,
.profile td {
  padding: 6px;
}

tr.profile-odd {
  background-color: #ffefef;
}
tr.profile-even {
  background-color: #efffef;
}

.background {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
}

.bg-off-white {
  background-color: #fafafa;
}

.three-columns {
  width: 230px;
  padding: 0 5px;
  float: left;
}

.three-columns h3 {
  font-size: 18pt;
}

.three-columns li {
  font-weight: 400;
}

.blue-button {
  background-color: #49e;
  border-radius: 2px;
  border: 1px solid #3d80b8;
  color: #fafafa;
  cursor: pointer;
  padding: 0.5em 10px;
  text-decoration: none;
  text-shadow: none;
}

.blue-button:hover {
  background-color: #5af;
  text-decoration: none;
}

.blue-button:active {
  border: 1px solid #3d80b8;
  background-color: #5af;
}

.blue-button:disabled,
.blue-button.disabled {
  background-color: #ddd;
  border: 1px solid #ccc;
  color: #999;
  cursor: default;
  text-shadow: none;
}

.white-button {
  background-color: #fff;
  border-radius: 2px;
  border: 1px solid #eee;
  color: #2699ee;
  cursor: pointer;
  padding: 0.5em 10px;
  text-decoration: none;
  text-shadow: none;
}
.white-button:hover {
  background-color: #ddd;
  text-decoration: none;
}

.gray-button {
  background-color: #ccc !important;
  border-radius: 2px;
  border: 1px solid #aaa;
  color: #111;
  cursor: pointer;
  padding: 0.5em 10px;
  text-decoration: none;
  text-shadow: none;
}

.gray-button:hover {
  background-color: #ddd !important;
  text-decoration: none;
}

.gray-button:active {
  border: 1px solid #ccc;
  background-color: #eee !important;
}

.gray-button:disabled,
.gray-button.disabled {
  background-color: #ddd;
  border: 1px solid #ccc;
  color: #999;
  cursor: default;
  text-shadow: none;
}

.red-button {
  background-color: #f88;
  border-radius: 2px;
  border: 1px solid #f55;
  color: #fafafa;
  cursor: pointer;
  padding: 0.5em 10px;
  text-decoration: none;
  text-shadow: none;
}

.red-button:hover {
  background-color: #f55;
  text-decoration: none;
}

.red-button:active {
  border: 1px solid #f55;
  background-color: #f44;
}

.red-button:disabled,
.red-button.disabled {
  background-color: #ddd;
  border: 1px solid #ccc;
  color: #999;
  cursor: default;
  text-shadow: none;
}

.black-button {
  background-color: #333;
  border-radius: 2px;
  border: 1px solid #333;
  color: #fafafa;
  cursor: pointer;
  text-decoration: none;
  text-shadow: none;
  font-size: medium;
}

.large-link-button {
  font-size: 1.3em;
  background: none;
  border: none;
  font-weight: 700;
  cursor: pointer;
}

.large-link-button:hover {
  text-decoration: underline;
}

.link-button {
  background: none;
  border: none;
  color: #222;
  cursor: pointer;
}

.link-button:hover {
  text-decoration: underline;
}

.link-blue {
  color: #49e;
  font-size: 15px;
  font-weight: 700;
}

.customer-bg {
  position: relative;
  width: 230px;
  height: 142px;
  z-index: 0;
  background: rgba(255, 0, 0, 0.1);
  overflow: hidden;
  border-radius: 3px;
}

.customer-bg img.bg {
  width: 100%;
  height: 100%;
}

.customer-bg img.customer-logo,
.customer-fg img.customer-logo {
  position: absolute;
  right: 0;
  bottom: 0;
}
.customer-fg {
  border-radius: 3px;
  cursor: pointer;
  height: 142px;
  margin-top: -142px;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  opacity: 0;
  overflow: hidden;
  position: relative;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all ease 0.2s;
  -moz-transition: all ease 0.2s;
  -ms-transition: all ease 0.2s;
  -o-transition: all ease 0.2s;
  transition: all ease 0.2s;
  width: 230px;
  z-index: 1;
}

.customer-fg h4,
.customer-fg p {
  color: white;
  margin: 5px;
}

.customer-fg p {
  font-style: italic;
}

.customer-fg p:before,
.customer-fg p:after {
  font-size: 14pt;
  font-weight: 700;
  line-height: 13px;
  content: '"';
}

.customer-fg:hover {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: alpha(opacity=100);
  opacity: 1;
  background-color: #555;
  background-color: rgba(0, 0, 0, 0.7);
}

.customer-spacer {
  height: 10px;
  display: block;
}

.cta {
  font-size: 14pt;
  width: 208px;
  margin: 12pt 0;
}

.ce {
  font-family: sculpteo-icons;
  font-style: normal;
  font-weight: 400;
  color: #888;
  margin-right: 0.5em;
}

.ce-repair:before {
  content: "£";
}
.ce-pricing:before {
  content: "c";
}
.ce-delivery:before {
  content: "#";
}
.ce-share:before {
  content: "[";
}
.ce-format:before {
  content: "'";
}
.ce-stock:before {
  content: "+";
}
.ce-costs:before {
  content: "$";
}
.ce-white:before {
  content: "<";
}
.ce-multichannel:before {
  content: "m";
}
.ce-hosting:before {
  content: "&";
}
.ce-secure:before {
  content: "l";
}
.ce-support:before {
  content: "-";
}

.gallery-column {
  float: left;
  height: 240px;
  margin-right: 5px;
  margin-top: 3px;
}

.gallery-column img {
  float: left;
  margin-bottom: 5px;
  transition: all ease 0.2s;
}

.gallery-column img:hover {
  box-shadow: 0 0 1px 1px white, 0 0 4px 1px #111;
}

.gallery-column img.small {
  width: 75px;
  height: 75px;
}

.gallery-column img.large {
  width: 155px;
  height: 155px;
}

.gallery-column .double > a:nth-of-type(2) {
  float: right;
}

.gallery-column .single {
  width: 75px;
}

.gallery-column .double {
  width: 155px;
}

.testimonial-link {
  text-decoration: none !important;
}

.press-container {
  display: inline-block;
  margin-top: 1em;
  max-height: 110px;
  overflow: hidden;
  padding-bottom: 1em;
  position: relative;
}

.press-container a {
  display: inline-block;
  height: 93px;
  margin: 0 5px;
  text-align: left;
  width: 150px;
}

.press-container img {
  opacity: 0.75;
  -webkit-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  filter: grayscale(100%);
  filter: gray;
  transition: all 0.4s ease;
}

.press-container a:hover img {
  opacity: 1;
  -webkit-filter: grayscale(0);
  -ms-filter: grayscale(0);
  filter: grayscale(0);
  filter: none;
}

.customer-padding {
  padding: 3px;
  background-color: white;
}

#profile-button {
  padding-right: 0;
}

.breadcrumbs {
  background: white;
  border-radius: 0 0 3px 3px;
  box-shadow: inset 0 -1px 1px #ccc;
  margin-left: -1px;
  padding: 5px 20px;
}

.a_displace {
  display: block;
  position: relative;
  top: -120px;
  visibility: hidden;
}
.wrapper-checkout-form {
  float: left;
  text-align: left !important;
  position: relative;
  width: 640px;
  margin-top: 20px;
}

.remember-cart {
  float: right;
  width: 280px;
  clear: both;
  margin-top: 30px;
  padding: 10px;
  color: #333;
  background-color: #ddd;
}

.remember-cart h2 {
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
}

.list-cart-checkout {
  margin-left: 20px;
  font-size: small;
}

#checkout-step-1 .avatar-checkout {
  float: right;
  width: 80px;
}
#checkout-step-1 .checkout-form-infos {
  border-collapse: separate;
  border-spacing: 2px;
}

.block-detail-infos {
  display: inline-block;
}

.resume-coupon {
  font-size: 1.3em;
}

.remember-cart .resume-coupon {
  width: 100%;
  font-size: 1em;
}
.remember-cart .tab-price {
  text-align: right;
}

.remember-cart-order {
  font-size: 1.2em;
}

.list-cart-checkout > li {
  list-style: none;
}

.list-cart-checkout > li > ul > li {
  list-style: none;
  text-indent: 2.5em;
  font-style: italic;
}

.remember-cart-order .discount-info {
  display: none;
}

.billing_addr-section input[readonly="readonly"] {
  color: #b3b3b3;
}

.page-header {
  padding-bottom: 9px;
  margin: 20px 0 30px;
  border-bottom: 1px solid #eee;
}

.delivery-tiny-input,
.delivery-small-input,
.delivery-med-input,
.delivery-large-input {
  margin-bottom: 10px;
  margin-left: 5px;
}

select.delivery-tiny-input,
select.delivery-small-input,
select.delivery-med-input,
select.delivery-large-input {
  padding: 4px 0;
}

.delivery-tiny-input {
  width: 40px !important;
}

.delivery-small-input {
  width: 80px !important;
}

.delivery-med-input {
  width: 202px !important;
}

.input_field_error {
  box-shadow: rgb(234, 80, 80) 0px 0px 3px;
  border-color: rgb(234, 80, 80) !important;
  background: rgb(255, 238, 238);
}

.form_error input.on_whitebg {
  border: 1px solid red !important;
}

.form_error select {
  border: 1px solid red !important;
}

.delivery-large-input {
  width: 320px !important;
}

.checkout-med-field-hint {
  width: 245px;
  display: inline-block;
  font-style: italic;
  line-height: 13px;
  margin-top: 2px;
  color: #666;
  vertical-align: middle;
}

.to-pay td {
  font-weight: 700;
}

label.tight {
  width: 150px;
  display: inline-block;
  text-align: right;
}

.block-detail-infos label {
  margin-left: 5px;
  display: inline-block;
  margin-top: 15px;
  vertical-align: middle;
}

.push-r-8 {
  /*Stupid tables mixed with divs obliges this è_é WTF man... */
  margin-left: 8px;
}

.checkout-indicator {
  color: #bbb;
}

.address2 {
  margin-left: 158px;
}

.big-cta {
  padding: 10px 50px;
  font-weight: 700;
  text-shadow: none;
  font-size: 1.2em;
}

.remember-cart-details {
  margin-top: 20px;
  border-top: dotted 1px #ccc;
}

.visibility-selector,
.scale-selector {
  display: inline-block;
  margin: 0 auto;
  width: 49%;
}

.main-column {
  width: 640px;
  float: left;
}

.side-column {
  width: 320px;
  float: right;
}

.upload-icon {
  background-image: url("images/upload_icon.png");
  height: 124px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 124px;
}

.visibility-selector label,
.scale-selector label {
  font-size: 1.5em !important;
  font-weight: 700;
}

.visibility-selector select,
.scale-selector select {
  font-size: 0.8em;
  padding: 0.2em;
  height: 2em;
}

.stripped {
  border: transparent solid 1px !important;
  margin: 0;
}

.search-bar {
  background-image: url("images/search.png");
  background-repeat: no-repeat;
  background-position: 99%;
}

.upload-terms {
  margin-top: 20px;
}

.alert-neutral-subtitle {
  font-weight: normal;
  text-transform: none;
}

.alert-neutral-subtitle .beta-sticker {
  font-size: 10px;
  margin: 8px;
}

#upload-form label {
  font-weight: 700;
}

#upload-form .checkbox label {
  font-weight: normal;
}

#remember-cart-shipping-description {
  font-weight: bold;
}

#remember-cart-shipping-deadline {
  font-style: italic;
}

#shipping .shipping-choice > ul:first-child > li:hover {
  border-radius: 3px;
  background-color: #fff;
  border: 1px solid #ccc;
}

#shipping .shipping-choice > ul:first-child > li {
  border: 1px solid transparent;
}

.discount-wrapper div {
  display: inline-block;
}

.coupon-selection {
  margin-top: 20px;
}

.coupon-wrapper {
  display: inline-block;
  vertical-align: top;
  margin-top: -2px;
  margin-left: 5px;
}

.coupon-wrapper a {
  background-color: white;
  border-radius: 3px;
  border: 1px solid #ccc;
  box-shadow: 1px 1px 3px #ccc;
  padding: 5px;
  text-decoration: none;
  color: #222;
  margin-bottom: 5px;
  display: inline-block;
  min-width: 245px;
}

.coupon-wrapper a:hover {
  box-shadow: 2px 2px 3px #ccc;
}

.coupon-wrapper a:active {
  box-shadow: 0 0 3px #ccc;
}

.coupon-wrapper a:focus {
  border: 1px solid #49e;
}

.print-success {
  float: right;
  display: block;
  text-align: center;
  width: 80px;
}

.print-success a {
  display: block;
  padding-top: 32px;
  text-align: center;
  width: 80px;
  background-image: url("images/print.png");
  background-size: 32px;
  background-repeat: no-repeat;
  background-position: top center;
  border: 1px solid transparent;
  border-radius: 3px;
}

.print-success a:hover {
  background-color: white;
  border: 1px solid #ccc;
}

.normal-list {
  list-style: decimal outside;
  margin-left: 30px;
  font-size: 1.2em;
}

.order-contact-infos {
  font-size: 1.2em;
}

.order-contact-infos h4 {
  font-weight: bold;
}

.payment-and-delivery {
  border-top: 1px solid #ccc;
}

.payment-and-delivery > * {
  width: 40%;
}

.payment-and-delivery > div:first-child:last-child {
  width: 100%;
}

.payment-details td {
  padding: 3px 10px;
}

.payment-details td.value {
  text-align: right;
}

.payment-details .total-paid {
  background-color: #ddd;
  border-top: 1px solid #ccc;
}

.order-success-thumbnail {
  margin-right: 10px;
  border-radius: 3px;
}

.order-success-price-breakdown {
  float: right;
  margin-right: 20px;
}

.order-success-list {
  background: white;
  border-radius: 3px;
  border: 1px solid #ccc;
  font-size: 1.2em;
}

body.order-success .child-order {
  position: relative;
}

body.order-success .child-order h2 {
  font-size: 24px;
  font-weight: normal;
  font-family: inherit !important;
}
body.account-order-tracking .child-order h2 {
  font-family: inherit !important;
}

body.order-success .child-order h2 span {
  float: right;
  font-weight: bold;
}

body.order-success .child-order:before,
body.checkout .shipping-choice:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 8px;
  left: -8px;
}

body.order-success .child-order.production-mode-economy_mode:before,
body.checkout .shipping-choice-economy_mode:before {
  background: #46af42;
}

body.order-success .child-order.production-mode-fast_mode:before,
body.checkout .shipping-choice-fast_mode:before {
  background: #49e;
}

body.order-success .child-order hr.clearfix:last-child {
  display: none;
}

#topay-resume .hint {
  max-width: 345px;
}

#topay-resume table .resume-first-column {
  width: 150px;
  text-align: right;
}

#topay-resume table .resume-second-column {
  min-width: 210px;
  text-align: right;
}

#topay-resume table .resume-third-column {
  width: 100px;
  text-align: right;
}

#topay-resume {
  margin-top: 20px;
  margin-bottom: 10px;
}

#topay-resume .resume-coupon .tab-coupon td,
#topay-resume .resume-coupon .tab-discount td {
  border-top: 1px dotted #ccc;
  border-bottom: 1px dotted #ccc;
  margin-bottom: 5px;
  margin-top: 5px;
}

.checkout-error-side {
  display: inline-block;
  margin: 0 5px 15px;
}

.breadcrumbs_gallery li {
  cursor: default;
  display: inline-block;
  font-size: 13px;
  padding: 0 5px 12px;
  vertical-align: baseline;
}

.breadcrumbs_gallery {
  border-bottom: 0px solid #ccc;
  display: inline-block;
  margin: auto;
  height: 40px;
}
.breadcrumbs_gallery li.breadcrumbs-spacer {
  background-image: url("images/right-arrow.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 12px;
  opacity: 0.5;
  border-color: #9a9a9a;
}
.checkout-progress li.checkout-spacer {
  background-image: url("images/right-arrow.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 25px;
  opacity: 0.5;
  border-color: #9a9a9a;
}
.checkout-progress li.checkout-spacer.was-active {
  opacity: 1;
  border-color: #49e;
}

#print_logo {
  display: none;
  margin: 0 auto;
}

@media print {
  .nav-header,
  #footer,
  #feedback_tab_text,
  .checkout-progress-wrapper,
  .print-success,
  #backToSculpteo,
  .background.bg-off-white,
  .sponsorCTA {
    display: none !important;
  }

  #print_logo {
    display: block;
  }
}

.select-hint {
  color: #999;
}

.select2-highlighted .select-hint {
  color: #ddd;
}

#upload-file {
  padding: 0;
}

#dragDropLabel {
  margin-bottom: 20px;
}

#uploadIcon {
  margin-top: 20px;
}

#upload-file .dz-preview {
  margin: 0;
}

#uploadContainer {
  padding: 20px;
}

body > .wide.relative {
  min-height: 515px;
  display: inline-block;
}

.googleRemarketing img {
  position: absolute;
}

.account_box {
  border: 1px solid #ccc;
  border-radius: 3px;
  margin: 50px 3% 0 3%;
}

.account_box h2 {
  text-align: center;
}

.account-login .embedded-wide,
.account-login .embedded-wide .embedded-content,
.account-login .embedded-wide .wrapper,
.account-login .embedded-wide .TraditionalLogin > div,
.account-signup .embedded-wide,
.account-signup .embedded-wide .embedded-content,
.account-signup .embedded-wide .wrapper {
  width: 100% !important;
}

.account-login .embedded-wide .TraditionalLogin h1 {
  font-size: 28px;
  margin-top: 30px !important;
}

.account-login .embedded-wide .fleft.account_box {
  margin: 0;
  border: 0;
  border-radius: none;
}

.account-login .embedded-wide .fright.account_box {
  display: none;
}

.account-signup .embedded-wide .w480 {
  width: 100%;
  margin-top: 0;
}

#sales_terms_label:hover {
  cursor: pointer;
}
.account-label {
  font-size: 1.2rem;
  line-height: 32px;
  vertical-align: top;
}
.account-icon {
  background-image: url(images/account.png);
  display: inline-block;
  height: 32px;
  margin-right: 8px;
  width: 32px;
}
.account-icon.icon-address-book {
  background-image: none;
}
.account-icon.icon-address-book:before {
  color: #777777;
  font-size: 24px;
  position: relative;
  top: 4px;
  left: 4px;
}

.public-info {
  background-position: 0 0;
}
.messages {
  background-position: -32px -64px;
}
.perso-info {
  background-position: -34px -132px;
}
.password {
  background-position: 0 -100px;
}
.account-icon .basket {
  background-image: url(images/cart_32.png);
  height: 30px;
}
.history {
  background-position: -68px 0;
}
.language {
  background-position: 10px 10px;
}
.newsletter {
  background-position: -34px -100px;
}
.prefs {
  background-position: -1px -33px;
}
.vendor {
  background-position: -68px -68px;
}
.notification_prefs {
  background-position: -68px -170px;
}
.affiliate {
  background-position: 0 -66px;
}
.shop {
  background-position: -68px -33px;
}
.upload {
  background-position: -68px -100px;
}
.tracking {
  background-position: 0 -136px;
}

.embedIt {
  background-position: 1px -170px;
}

.publicGallery {
  background-position: -32px -170px;
}

.favorites {
  background-position: -32px 0;
}

.quote-block,
.coupon-block,
.order-block {
  border: 1px solid #ccc;
  border-radius: 3px;
}

.quote-block table > tbody tr:nth-child(odd) td,
.coupon-block table > tbody tr:nth-child(odd) td,
.order-block table > tbody tr:nth-child(odd) td {
  background: #f5f5f5;
}

.quote-block table > tbody tr:hover td,
.coupon-block table > tbody tr:hover td,
.order-block table > tbody tr:hover td {
  background: #e0e0e0;
}

.maxH-350 {
  max-height: 350px;
  overflow: hidden;
}

.loadmore {
  bottom: 0;
  left: -5px;
  position: absolute;
  width: 100%;
}

.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.payment-details-bank-cheque {
  font-size: 1.2em;
}

#staff-info-container {
  float: right;
}

.staff-info-heading {
  font-size: 12px;
  font-weight: bold;
  text-align: initial; /* reset the global text-align: center; */
  background-color: #3fe6be;
  padding: 0 16px;
  width: fit-content;
  color: white;
  border-radius: 4px 4px 0px 0px; /* rounded top corners */
}

#staff-info {
  background-color: #f3f3f3;
  padding: 10px;
  border-radius: 0px 4px 4px 4px; /* rounded bottom and top right*/
  border: 1px solid #e2e2e2;
  font-size: large;
  text-align: initial; /* reset the global text-align: center; */
  display: flex;
  flex-direction: column;
  gap: 10px;
}

#staff-info .staff-info-item span:first-child {
  font-weight: bold;
}

.sculpteo-clipboard-copy {
  cursor: pointer;
  user-select: none;
  opacity: 0.66;
}

.sculpteo-clipboard-copy-icon {
  display: inline-block;
  background-image: url(../images/icons/heroicons-document-duplicate.svg);
  height: 1em;
  width: 1em;
  margin-left: 4px;
  font-size: smaller;
}

.sculpteo-clipboard-flash {
  animation: sculpteo-clipboard-flash 0.3s 1;
}

.sculpteo-comment-icon {
  display: inline-block;
  background-image: url(../images/icons/heroicons-chat-bubble-left-ellipsis.svg);
  height: 1em;
  width: 1em;
  margin-left: 4px;
  font-size: smaller;
  background-color: yellow;
}

.thumbnail-with-comments {
  border: 3px solid yellow !important;
}

@keyframes sculpteo-clipboard-flash {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

#myaccount > .fright {
  border-left: 1px solid #ccc;
  height: 100px;
  width: 29%;
}

#myaccount > .fleft {
  width: 69%;
}

#account-update-type {
  width: 450px;
}

#account-update-user {
  width: 449px;
  margin-left: 113px;
}

#account-update-user table > .form448 {
  margin-left: 156px;
}

.user-update-address {
  width: 450px;
}

.account .preferences_form_section {
  width: 475px;
}

.account .preferences_form_section select {
  font-size: 14px;
}

body.theme .hide-on-theme {
  display: none !important;
}

/* Shared between print and cart pages */
.icon-production-mode:before {
  content: "";
  display: inline-block;
  width: 20px;
  height: 24px;
  margin: 0 9px 0 0;
  background: transparent url(images/production_modes.png) -15px 0 no-repeat;
  background-size: 64px;
  vertical-align: middle;
}

.icon-production-mode.icon-production-mode-next_day_mode:before {
  background-position: -50px 0;
}

.icon-production-mode.icon-production-mode-fast_mode:before {
  background-position: -30px 0;
}

.icon-production-mode.icon-production-mode-economy_mode:before {
  background-position: 5px 0;
}

/* Shared between print and cart pages */
.select-production-mode {
  display: table;
  text-align: left;
}

.select-production-mode * {
  display: table-cell;
}

.select-production-mode .select-material-price {
  text-align: right;
  padding-left: 10px;
}

@media (max-width: 1023px) {
  * {
    box-sizing: border-box; /* should be set for ALL */
  }

  body.responsive {
    overflow-x: hidden; /* i didn't found the over-sized culprit, so i cheated. */
  }

  body.responsive > .wide.relative {
    overflow: hidden;
  }

  .responsive #site_content,
  .responsive .wrapper,
  .responsive #static-content-wrapper,
  .responsive .main-column,
  .responsive .w480,
  .responsive #form-base-content,
  .responsive .form448,
  .responsive .wrapper480,
  .responsive .w640 {
    width: 100% !important;
  }

  .responsive .press-container {
    max-height: none;
  }

  .responsive #uploadContainer {
    margin-bottom: 20px;
  }

  .responsive .cart-title-checkout ul {
    display: none;
  }

  .responsive .remember-cart-order h2 {
    font-size: 22px;
  }

  .responsive .remember-cart-details {
    display: none !important;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  body.responsive.design-upload .side-column {
    width: 100% !important;
    float: none;
    display: flex;
    display: -webkit-flex;
    -webkit-justify-content: space-between;
    justify-content: space-between;
  }

  body.responsive.design-upload .side-column > div {
    width: 49%;
  }

  .responsive .remember-cart,
  .wrapper-checkout-form {
    float: none;
    width: 100%;
  }

  body.responsive.cart #basket .col-md-6 {
    width: 41.66666666666667%;
  }

  body.responsive.cart #basket .col-md-6 + .col-md-2 {
    width: 25%;
  }

  body.order-success .child-order h2 span {
    display: block;
    float: none;
    position: relative;
    left: 34px;
    font-size: 18px;
  }
}

@media (max-width: 767px) {
  .responsive .sm-clr {
    clear: both;
    display: block;
  }
  .responsive .account_box {
    width: 100% !important; /* overrides inline style */
    float: none !important; /* overrides another !important */
    margin: 20px auto 0;
  }

  .responsive .account_box.fright a {
    float: none !important;
  }

  .responsive .account_box.fleft .relative div {
    width: 100% !important;
    float: none !important;
    text-align: center;
  }

  .responsive #myaccount > .fleft,
  .responsive #myaccount > .fright {
    border: none;
    width: 100%;
    float: none !important;
    height: auto;
    padding: 0;
  }

  .responsive #account-update-type,
  .responsive #account-update-user {
    width: 100%;
    margin: 0;
  }

  .responsive #account-update-type {
    display: flex;
    display: -webkit-flex;
    -webkit-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }

  .responsive #account-update-type .fleft,
  .responsive #account-update-type .fright,
  .responsive #account-update-type label {
    float: none !important;
  }

  .responsive #account-update-type input + label {
    display: inline-block;
  }

  .responsive #account-update-type br {
    display: none;
  }

  .responsive #account-update-user label {
    display: block;
    text-align: left;
  }

  .responsive #account-update-user table > .form448 {
    margin-left: 0;
  }

  .responsive #account-update-user .delivery-large-input {
    width: 100% !important;
    margin-left: 0;
  }

  .responsive .user-update-address {
    width: 100%;
    float: none !important;
  }

  .responsive .user-update-address .delivery-large-input {
    width: 100% !important;
    margin-left: 0;
  }

  .user-update-address label {
    text-align: left;
  }

  .responsive .user-update-address table input {
    width: 100% !important;
    margin-left: 0;
  }

  .responsive.account .preferences_form_section {
    width: 100%;
    float: none !important;
    margin-left: 0 !important;
    margin-top: 16px;
  }

  .responsive #dragDropLabel {
    display: none;
  }

  .responsive #upload-file {
    display: flex;
    display: -webkit-flex;
    -webkit-flex-direction: column-reverse;
    flex-direction: column-reverse;
    padding: 16px;
    margin-bottom: 0;
  }

  .responsive #upload-file.dz-started {
    padding: 0;
  }

  .responsive #upload-file > div {
    position: static;
    margin-top: 0;
  }

  body.responsive.design-upload .main-column {
    float: none;
  }

  body.responsive.design-upload .side-column {
    width: 100% !important;
    float: none;
  }

  body.responsive.design-upload .dropzone .dz-preview {
    position: relative !important;
  }

  .responsive .payment-details {
    padding-left: 0;
  }

  .responsive .remember-cart-order h2 {
    font-size: 22px;
  }

  .responsive .block-detail-infos label {
    margin-top: 0;
  }

  .responsive .organization-section {
    margin-top: 15px;
  }

  .order-contact-infos > div {
    margin: 8px 0;
  }

  body.order-success .child-order h2 {
    font-size: 18px;
    padding-left: 20px;
    margin-bottom: 16px;
  }

  body.order-success .child-order h2:before {
    position: absolute;
    top: 16px;
    left: 8px;
  }

  body.order-success .child-order h2 span {
    float: none;
    font-size: 14px;
    margin-top: 4px;
    display: block;
  }

  body.order-success .child-order .cart-thumbnail {
    display: none;
  }

  body.order-success .payment-and-delivery > * {
    width: 100%;
    float: none !important;
  }

  body.order-success .delivery-details {
    padding: 8px;
  }

  body.order-success #backToSculpteo {
    display: block;
    float: none !important;
    margin: 8px 0;
    text-align: center;
  }

  #contactFormWrapper {
    width: 100% !important;
  }

  #contactFormWrapper #messageContainer {
    width: 100% !important;
  }
}
