.product {
  width: 200px;
  height: 295px;
  border: solid 1px #dedede;
  border-radius: 3px;
  float: left;
  position: relative;
  margin: 4px 4px 8px 4px;
  overflow: hidden;
  cursor: pointer;
  font-family: "Open Sans", sans-serif;
}

.product:hover {
  box-shadow: 1px 1px 2px #ccc;
}

/* what is this?
[lang=de] body.design-gallery .product {
 	height: 305px;
} */

.product h3 {
  font-size: 13pt;
  color: white;
  font-weight: bold;
  line-height: normal;
  padding: 4px 2% 5px 3%;
  position: absolute;
  background-color: black;
  background-color: rgba(0, 0, 0, 0.4);
  text-align: left;
  text-overflow: ellipsis;
  width: 100%;
  top: 143px;
  overflow: hidden;
  white-space: nowrap;
}

.product h3 a {
  color: white;
}

.product h4 {
  font-size: 13px;
  color: #999;
  font-weight: normal;
  margin: 0 5px;
  line-height: normal;
  text-align: left;
  width: 100%;
  height: 18px;
  overflow: hidden;
  white-space: nowrap;
}

.product h4 strong {
  color: black;
  font-weight: normal;
}

.product p {
  font-size: 13px;
  color: #898989;
  -webkit-font-smoothing: subpixel-antialiased;
  font-weight: normal;
  line-height: normal;
  text-align: left;
  margin: 8px 5px;
  width: 95%;
  min-height: 50px;
  max-height: 50px;
  overflow: hidden;
  position: relative;
}

.product a.price_link {
  font-size: 11pt;
  color: black;
  font-weight: bold;
  line-height: normal;
  position: absolute;
  bottom: 5px;
  right: 5px;
  height: 18px;
}

.product .product-name {
  color: black;
  text-decoration: none;
}

.product .product-name:hover {
  text-decoration: underline;
}

.product img.product-image {
  height: 200px;
  width: 200px;
}

.product .price_link {
  text-decoration: none;
}

.product .price_link:hover {
  text-decoration: underline;
}

.product .product-image-container {
  background-image: url("images/background_3d.png");
  position: relative;
  height: 175px;
  overflow: hidden;
  width: 100%;
}
.product .product-image-container .product-image-3d {
  /* Trick to display a centered div which can be rectangular or squared (http://jsfiddle.net/LSKRy/) */
  position: relative;
  display: inline-block;
  right: -50%;
}
.product .product-image-container .product-image-3d > div {
  position: relative;
  left: -50%;
  height: 175px;
}

.sidebar {
  background: white;
  border-left: 1px solid #ccc;
}
